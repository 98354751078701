import React, { useState } from "react";
import { Input, Form, Modal, Radio, Switch, Spin } from "antd";
import benefitstyle from "../../style/ClientBenefits.module.css";

export default function AddBenefit({
  allocateModal,
  setAllocateModal,
  onFinish,
}) {
  const [loading, setLoading] = useState(false);

  const triggerAnimation = (time) => {
    setTimeout(
      () =>
        window.confetti({
          particleCount: 1000,
          spread: 200,
          origin: { y: 0.6 },
        }),
      time
    );
  };

  const onFormFinish = async (values) => {
    setLoading(true);

    await onFinish(values);
    setLoading(false);
    setAllocateModal(false);

    triggerAnimation(100);
    triggerAnimation(1500);
    triggerAnimation(2800);
    triggerAnimation(4300);
    triggerAnimation(6000);
  };
  return (
    <Modal
      title="Allocate Funds"
      visible={allocateModal}
      onOk={() => {}}
      onCancel={() => setAllocateModal(false)}
      width={700}
    >
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFormFinish}
      >
        <div className="row">
          <div className={`col-md-12`}>
            <Form.Item name="contribution">
              <Radio.Group>
                <Radio value={"employer"}>
                  Employer Contribution: We'll charge your employer directly
                  once you click Submit
                </Radio>
                <Radio value={"employee"}>
                  Employee Contribution: We'll take you to a secure check out
                  form once you click Submit
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Make this contribution automatically recur every month:"
              name={"recurring"}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label=""
              name={"amount"}
              rules={[
                {
                  required: true,
                  message: `Please input the amount you want to fund!`,
                },
              ]}
            >
              <Input
                placeholder={"Amount"}
                min={0.5}
                className={benefitstyle.input}
              />
            </Form.Item>
          </div>
          <div className="col-md-12">
            {loading ? (
              <div className="ml-3 my-3 mr-0">
                <Spin />
              </div>
            ) : (
              <button type="submit" className="primaryBtn">
                Submit
              </button>
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
}
