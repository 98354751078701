import React, { useState } from "react";
import substyles from "../../style/authStep.module.css";
import { Slider, Row, Col, Radio } from "antd";

export default function Questions({
  getAnswersOne,
  getAnswersTwo,
  getAnswersThree,
}) {
  // Question
  const [questionOne, setQuestionOne] = useState(1);
  const [questionTwo, setQuestionTwo] = useState(1);
  const [questionThree, setQuestionThree] = useState("");

  const q1 = (value) => {
    setQuestionOne(value);
    getAnswersOne(value);
  };
  const q2 = (value) => {
    setQuestionTwo(value);
    getAnswersTwo(value);
  };
  const q3 = (value) => {
    setQuestionThree(value);
    getAnswersThree(value);
  };
  return (
    <div className={substyles.container} style={{ marginTop: "-60px" }}>
      <h2 className='mb-2'>Personalize Your Experience</h2>
      <p className='mb-2' style={{ textAlign: "center" }}>
        Help us customize your Climate Benefits dashboard by answering 3 simple
        questions.
      </p>
      <div
        className='d-flex flex-wrap'
        style={{ height: "400px", overflowY: "auto" }}>
        <div className='mt-5'>
          <div className='mb-3'>
            <h1 style={{ fontSize: "20px" }}>
              1. On a scale from 1-10 how environmentally conscious is your
              company?
            </h1>
            <Row style={{ marginLeft: "10px" }}>
              <Col span={12}>
                <Slider
                  min={1}
                  max={10}
                  onChange={q1}
                  value={typeof questionOne === "number" ? questionOne : 0}
                />
              </Col>
              <div
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  fontWeight: "700",
                }}>
                {questionOne}
              </div>
            </Row>
          </div>
          <div className='mb-3'>
            <h1 style={{ fontSize: "20px" }}>
              2. On a scale from 1-10 how environmentally conscious are you
              personally?
            </h1>
            <Row style={{ marginLeft: "10px" }}>
              <Col span={12}>
                <Slider
                  min={1}
                  max={10}
                  onChange={q2}
                  value={typeof questionTwo === "number" ? questionTwo : 0}
                />
              </Col>
              <div
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  fontWeight: "700",
                }}>
                {questionTwo}
              </div>
            </Row>
          </div>
          <div>
            <h1 style={{ fontSize: "20px" }}>
              3. Does your company have any sustainability or climate change
              initiatives currently?
            </h1>
            <Radio.Group style={{ marginLeft: "10px" }}>
              <Radio
                value='Yes'
                checked
                onChange={(e) => {
                  q3(e.target.value);
                }}>
                Yes
              </Radio>
              <Radio
                value='No'
                onChange={(e) => {
                  q3(e.target.value);
                }}>
                No
              </Radio>
            </Radio.Group>
          </div>
        </div>
      </div>
    </div>
  );
}
