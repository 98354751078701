import React, { useState } from "react";
import styles from "../style/subscriptionPlan.module.css";
import SubscriptionNumber from "./SubscriptionNumber";
import { changePricingPlan } from "../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import moment from "moment";
export default function SubscriptionPlan() {
  const [modalVisible, setModalVisible] = useState(false);
  const [plan, setPlan] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { subscription } = user;
  const onFinish = async (value) => {
    // const result = await dispatch(changePricingPlan(value));
    setModalVisible(false);
    // if (result) {
    if (true) {
      Modal.success({
        content:
          "Congratulations! You can track the number of available Employee seats from the Team page. Your Account Manager will reach out within 24 hours.",
      });
    }
  };

  return (
    <div>
      <h4 className='mb-5 mt-3 text-center'>
        Choose a plan that's right for you
      </h4>
      <SubscriptionNumber
        visible={modalVisible}
        setVisible={setModalVisible}
        plan={plan}
        onFinish={onFinish}
      />
      <div className='d-flex justify-content-center'>
        <div
          className={styles.container}
          style={{
            border:
              user.subscription.type == "trial"
                ? "2px solid #175cff"
                : "2px solid white",
          }}>
          <h3 className='text-center mb-2'>Trial</h3>
          <h5 className='text-center mb-4'>Free</h5>
          <span className='text-center mb-3'>$0 per employee / month</span>

          {subscription.type == "trial" ? (
            <>
              <p className='mb-1'> Currently Subscribed </p>
            </>
          ) : (
            ""
          )}
        </div>
        <div
          className={styles.container}
          style={{
            border:
              user.subscription.type == "starter"
                ? "2px solid #175cff"
                : "2px solid white",
          }}>
          <h3 className='text-center mb-2'>Starter</h3>
          <h5 className='text-center mb-4'>Paid Monthly</h5>
          <span className='text-center mb-3'>Starts at $149 / month</span>

          {subscription.type == "starter" ? (
            <>
              <p className='mb-1'>
                {" "}
                Currently subscribed with {subscription.numberOfEmployees}{" "}
                employee seats.{" "}
              </p>
            </>
          ) : (
            <button
              type='submit'
              className='primaryBtn'
              onClick={() => {
                setPlan("starter");
                setModalVisible(true);
              }}>
              Upgrade
            </button>
          )}
        </div>
        <div
          className={styles.container}
          style={{
            border:
              user.subscription.type == "enterprise"
                ? "2px solid #175cff"
                : "2px solid white",
          }}>
          <h3 className='text-center mb-2'>Enterprise</h3>
          <h5 className='text-center mb-4'>Paid Annually</h5>
          <span className='text-center mb-3'>Custom Pricing</span>

          {subscription.type == "enterprise" ? (
            <>
              <p className='mb-1'>
                {" "}
                Currently subscribed with {subscription.numberOfEmployees}{" "}
                employee seats.{" "}
              </p>
            </>
          ) : (
            <button
              type='submit'
              className='primaryBtn'
              onClick={() => {
                setPlan("enterprise");
                setModalVisible(true);
              }}>
              Upgrade
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
