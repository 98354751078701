import Types from "../constants/dashboard";
import BenefitTypes from "../constants/benefits";

import axios from "axios";
import apiRequest from "../../api/apiRequest";
import { fetchBenefits } from "./benefits";
import _ from "lodash";
import { getToken, onMessageListener } from "../../firebase";
import { useSelector } from "react-redux";

// actions
export const setTemplate = (data) => ({
  type: Types.SET_TEMPLATE,
  payload: data,
});

export const setActiveTicket = (data) => ({
  type: Types.SET_ACTIVE_TICKET,
  payload: data,
});

// export const getTemplate = () => async (dispatch) => {
//   apiRequest({
//     method: "GET",
//     url: "/invite-templates",
//   })
//     .then(async (res) => {
//       if (res.data.data) {
//         let template = JSON.parse(res.data.data.design);
//         if (typeof template == "string")
//           dispatch(setTemplate({ ...JSON.parse(template) }));
//         else dispatch(setTemplate(template));
//       }
//       // if (res.data.data.body.design) dispatch(setTemplate(res.data.data.body));
//     })
//     .catch((err) => {
//       // console.log(err);
//     });
// };

// export const getTickets = () => async (dispatch) => {
//   apiRequest({
//     method: "GET",
//     url: "/support-tickets",
//   })
//     .then(async (res) => {
//       if (res.data.data) {
//         dispatch({
//           type: Types.SET_TICKETS,
//           payload: res.data.data,
//         });
//       }
//     })
//     .catch((err) => {
//       // console.log(err);
//     });
// };

export const sendTicket = (data) => async (dispatch) => {
  let response = {
    _id: new Date().getTime(),
    status: "Open",
    closedOn: null,
    user: "62a22e82b781fe5c382042d4",
    topic: data.topic,
    severity: data.severity,
    subject: data.subject,
    description: data.description,
    ticketId: "TK-" + new Date().getTime(),
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
    __v: 0,
    thread: [],
  };

  dispatch({
    type: Types.SET_TICKETS,
    payload: response,
  });
};

export const deleteTicket =
  ({ ticketId, supportTickets }) =>
  async (dispatch) => {
    const response = supportTickets.filter(
      (ticket) => ticket.ticketId !== ticketId
    );
    dispatch({
      type: Types.DELETE_TICKET,
      payload: response,
    });
  };

// export const replyToTicket = (data) => async (dispatch) => {
//   apiRequest({
//     method: "POST",
//     url: `/support-tickets/message/${data.ticket}`,
//     data,
//   })
//     .then(async (res) => {
//       dispatch(getActiveTicket(data));
//     })
//     .catch((err) => {
//       // console.log(err);
//     });
// };

// export const getActiveTicket = (data) => async (dispatch) => {
//   apiRequest({
//     method: "GET",
//     url: `/support-tickets/${data.ticket}`,
//   })
//     .then(async (res) => {
//       if (res.data.data) {
//         dispatch({
//           type: Types.SET_ACTIVE_TICKET,
//           payload: res.data.data,
//         });
//       }
//     })
//     .catch((err) => {
//       // console.log(err);
//     });
// };

// export const getMeasureData = () => async (dispatch) => {
//   apiRequest({
//     method: "GET",
//     url: "/carbon-footprint-measures",
//   })
//     .then(async (res) => {
//       if (res.data.data) {
//         dispatch({
//           type: Types.SET_CARBON_MEASURES,
//           payload: res.data.data,
//         });
//       }
//     })
//     .catch((err) => {
//       // console.log(err);
//     });
// };

// export const addTemplate = (data) => async (dispatch) => {
//   apiRequest({
//     method: "POST",
//     url: "/invite-templates",
//     data: { html: data.html, design: JSON.stringify(data.design) },
//   })
//     .then(async (res) => {
//       if (res.data.data) {
//         let template = JSON.parse(res.data.data.design);
//         dispatch(setTemplate(template));
//       }
//     })
//     .catch((err) => {
//       // console.log(err);
//       // message.error(err.data.data);
//     });
// };
