import React, { useState } from "react";
import ServiceDetail from "../components/ServiceDetail";
import { connect } from "react-redux";
const mapState = (state) => ({
  active_service: state.benefits.active_service,
  user: state.auth.user,
});

function ClimateServiceDetail({ active_service, user, page }) {
  return (
    <div>
      <ServiceDetail
        data={active_service}
        imgRoute={"services"}
        userType={user.type}
        page={page}
      />
    </div>
  );
}

export default connect(mapState, null)(ClimateServiceDetail);
