import firebase from "firebase/app";
import "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyDt8ORaRqFxNLq1d08mj9zpU-CiecDF2l8",
  authDomain: "climate-benefits.firebaseapp.com",
  projectId: "climate-benefits",
  storageBucket: "climate-benefits.appspot.com",
  messagingSenderId: "820508693443",
  appId: "1:820508693443:web:e005173906b70a9518a376",
};

try {
  firebase.initializeApp(firebaseConfig);

  var messaging = firebase.messaging();
} catch (e) {
  var messaging = false;
  // console.log(e);
}

export const getToken = () => {
  if (messaging)
    return messaging
      .getToken({
        vapidKey:
          "BNO9iawLTzYB7Jc4b5FvW1ymc4JHW3o7Z4zLtKi59vgvRYeFul_eBfOdiQQSr_FFphcv4MAlnntBu0uhf70VUC4",
      })
      .then((currentToken) => {
        if (currentToken) {
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
          return currentToken;
        } else {
          // shows on the UI that permission is required
          return false;
        }
      })
      .catch((err) => {
        // console.log("An error occurred while retrieving token. ", err);
        // catch error while creating client token
      });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (messaging) {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    }
  });
