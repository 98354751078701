import React from "react";
import style from "../style/signupForm.module.css";
// import { Link } from "react-router-dom";
import { Form, Input, Button, Checkbox } from "antd";
// import { connect } from "react-redux";
// import { signIn } from "../redux/actions/auth";
// import { getToken, onMessageListener } from "../firebase";
import Types from "../redux/constants/auth";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// const mapDispatch = (dispatch) => ({
//   signIn: (data) => dispatch(signIn(data)),
// });

function SignInForm({ signIn }) {
  const history = useHistory();
  const dispatch = useDispatch();

  // const onFinish = async (values) => {
  //   const token = await getToken();
  //   const result = await signIn({
  //     ...values,
  //     deviceToken: token ? token : "not",
  //   });

  //   if (result) history.push(`/${result}`);
  //   if (true) history.push("/benefitprovider");
  // };

  // const onFinishFailed = (errorInfo) => {};
  const benefitData = {
    passwordText: "",
    isAdmin: false,
    link: null,
    status: null,
    deviceToken: "not",
    active: true,
    employerAccount: false,
    lastLogin: null,
    _id: "1",
    type: "benefitprovider",
    firstName: "Susan",
    lastName: "Thomas",
    email: "susan@climatebenefits.com",
    createdAt: "2022-05-28T15:16:43.100Z",
    updatedAt: "2022-05-28T15:16:43.100Z",
    __v: 0,
    fullName: "Benefit PRovider",
    id: "1",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyOTIzY2RiZDcxZGQ2MWQ1Y2U4NDdhYiIsImVtYWlsIjoibGtqbGtAa2pzbGRrLnNkZmRhc2QiLCJpYXQiOjE2NTM3NTEwMDMsImV4cCI6MTY4NTI4NzAwM30.KRfXgBktSVn_SROKX4piYY1B6YrisGVPbkBJzFWIq2k",
    origType: "benefitprovider",
    details: {
      currency: "USD",
    },
    subscription: {
      type: "trial",
      numberOfEmployees: "0",
    },
  };

  const employerData = {
    _id: "62a8abafdf65f66280ab9f30",
    passwordText: "",
    isAdmin: false,
    link: null,
    status: null,
    deviceToken: "",
    active: true,
    employerAccount: false,
    lastLogin: "2022-06-14T15:46:35.268Z",
    type: "employer",
    firstName: "Susan",
    lastName: "Thomas",
    email: "susan@climatebenefits.com",
    password: "$2a$10$co5qa8wXEvsu6I8stWr0ieqfP6gidCKwbeumx9R5Egrwx07PBxxc6",
    details: {
      monthlyFund: 0,
      monthlyRollover: false,
      employeeMatching: 0,
      points: false,
      leaderboard: false,
      tutorial: true,
    },
    stripeCustomerId: "cus_LsMZwM1v53DOb1",
    createdAt: "2022-06-14T15:39:27.877Z",
    updatedAt: "2022-06-14T15:46:35.268Z",
    __v: 0,
    about:
      '<p>Hello team, and welcome to your Climate Benefits dashboard!</p>\n<p>At our company, we believe that we each play a critical role in combating climate change. We know it is important to constantly be educating ourselves and engaging with actions to reduce carbon emissions. Read below about the Climate Benefits program we\'ve launched as a benefit that all of you can participate in.&nbsp;</p>\n<p>Here\'s an overview:</p>\n<ul>\n  <li>We\'ve partnered with&nbsp;<a href="https://www.climatebenefits.com/" title="Climate Benefits">Climate Benefits</a>&nbsp;to provide this service to our Employees. We\'re dedicated to combating climate change and want this to be our first step in this direction.&nbsp;</li>\n  <li>We\'ve given every employee $100 per month to allocate to carbon removal projects of your choice. We\'ve also allocated $50 per employee / month in \'employer matching\'. Meaning, if you pay out of pocket for carbon removal projects, we\'ll match those contributions up to $50!</li>\n  <li>You can track our company\'s environmental impact from the&nbsp;<a href="https://www.app.climatebenefits.com/employee" title="Home page">Home page</a>.&nbsp;If you have any questions, please reach out to [Your Name]. You can also contact the team at Climate Benefits directly by clicking "Support" on the top right navigation bar. There\'s also a link there to leave "Feedback" in case there are more features that you want to see.&nbsp;</li>\n</ul>',
    paymentMethods: [
      {
        id: "pm_1LAbwrIsqlJ1wpPgxlvTLjY1",
        object: "payment_method",
        billing_details: {
          address: {
            city: null,
            country: null,
            line1: null,
            line2: null,
            postal_code: "42424",
            state: null,
          },
          email: null,
          name: "susan@climatebenefits.com",
          phone: null,
        },
        card: {
          brand: "visa",
          checks: {
            address_line1_check: null,
            address_postal_code_check: "pass",
            cvc_check: "pass",
          },
          country: "US",
          exp_month: 4,
          exp_year: 2024,
          fingerprint: "Fk43rvWKVbhaYjlV",
          funding: "credit",
          generated_from: null,
          last4: "4242",
          networks: {
            available: ["visa"],
            preferred: null,
          },
          three_d_secure_usage: {
            supported: true,
          },
          wallet: null,
        },
        created: 1655221593,
        customer: "cus_LsMZwM1v53DOb1",
        livemode: false,
        metadata: {},
        type: "card",
      },
    ],
    subscription: {
      _id: "62a8ad60df65f66280ab9f6f",
      employer: "62a8abafdf65f66280ab9f30",
      type: "starter",
      numberOfEmployees: 50,
      status: "active",
      amount: 149,
      endDate: "2022-07-14T15:46:40.000Z",
      createdAt: "2022-06-14T15:46:40.915Z",
      updatedAt: "2022-06-14T15:46:40.915Z",
      __v: 0,
    },
    origType: "employer",
  };

  const employeeData = {
    _id: "6288cd46936dc21212821bda",
    passwordText: "",
    isAdmin: false,
    // link: {
    //   _id: "6288cd46936dc21212821bd8",
    //   passwordText: "",
    //   isAdmin: false,
    //   link: null,
    //   status: "Accepted",
    //   deviceToken: "not",
    //   active: true,
    //   employerAccount: false,
    //   lastLogin: "2022-06-08T16:15:34.628Z",
    //   type: "employer",
    //   firstName: "Employee",
    //   lastName: "",
    //   email: "employee@climatebenefits.com",
    //   details: {
    //     monthlyFund: 0,
    //     monthlyRollover: false,
    //     employeeMatching: 0,
    //   },
    //   stripeCustomerId: "cus_LjJ7EXSeGMG6iQ",
    //   createdAt: "2022-05-21T11:30:14.457Z",
    //   updatedAt: "2022-06-08T16:15:34.628Z",
    //   __v: 0,
    // },
    status: "Accepted",
    deviceToken:
      "dMEk6ExxGQTt1Zm9Cqu_zt:APA91bHEXr4xTdsjZC78o_RsVq1Xx-n9dctDwSO0KSL-6vFRa0IUw_w4FEBZlSwov0qzg5aCBbGpiJP-i3vDVqqlf_f5ldLOD4DNZMREkT0iExVkMy0CrkYlqDoA4OpxsMyfY47GCOzq",
    active: true,
    employerAccount: true,
    lastLogin: "2022-05-21T16:52:43.644Z",
    type: "employee",
    firstName: "Susan",
    lastName: "Thomas",
    email: "susan@climatebenefits.com",
    password: "$2a$10$LGrL2R0Lmgwcv4RAQuQQ7.X9bwG/l3nBmhCPv5MydLGw9..5CPEHC",
    createdAt: "2022-05-21T11:30:14.581Z",
    updatedAt: "2022-05-21T16:52:43.644Z",
    __v: 0,
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyODhjZDQ2OTM2ZGMyMTIxMjgyMWJkYSIsImVtYWlsIjoibGtqbGtAa2pzbGRrLnNkZiIsImlhdCI6MTY1NDcwNDk0MCwiZXhwIjoxNjg2MjQwOTQwfQ.qpoOFyVBq9Oelp_CxsA353V-5z6lUb6kuMy5BWbP284",
    origType: "employee",
    details: {
      currency: "USD",
    },
  };
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 1em",
        width: "100%",
        maxWidth: "710px",
        margin: "auto",
        paddingTop: "2em",
        marginBottom: "2em",
      }}
    >
      <h1 className="mb-3">Sign in</h1>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Benefit Provider</h5>
            <p class="card-text">
              Experience the dashboard of being a Benefit Provider.
            </p>
            <button
              to="/benefitprovider"
              onClick={() => {
                dispatch({ type: Types.SET_USER, payload: benefitData });
                history.push("/benefitprovider");
              }}
              class="btn btn-outline-primary"
            >
              Benefit Provider
            </button>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Employer</h5>
            <p class="card-text">
              Experience the dashboard of being an Employer.
            </p>
            <button
              onClick={() => {
                dispatch({
                  type: Types.SET_USER,
                  payload: employerData,
                });
                history.push("/employer");
              }}
              class="btn btn-outline-primary"
            >
              Employer
            </button>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Employee</h5>
            <p class="card-text">
              Experience the dashboard of being an Employee.
            </p>
            <button
              to="/benefitprovider"
              onClick={() => {
                dispatch({
                  type: Types.SET_USER,
                  payload: employeeData,
                });
                history.push("/employee");
              }}
              class="btn btn-outline-primary"
            >
              Employee
            </button>
          </div>
        </div>
      </div>
      {/* <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="row">
          <div className="col-md-7 mx-auto">
            <Form.Item
              label=""
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input placeholder="Email" value="emplyer@climatebanifits.com" />
            </Form.Item>
          </div>
          <div className="col-md-7 mx-auto">
            <Form.Item
              label=""
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input placeholder="Password" type="password" value="123456" />
            </Form.Item>
          </div>
          <div className="col-md-7 mx-auto">
            <div className="d-flex flex-column align-items-center justify-content-between">
              <button type="submit" className="primaryBtn mb-4">
                Go to Dashboard
              </button>
              <Link to='/forgot'>Forgot your password</Link>
            </div>
            <p className="text-center mt-4 mb-0">Don't have an account?</p>
            <p className="text-center mb-0 d-flex align-items-center justify-content-center">
              Sign up as an
              <Link to="/signup/employer" className="mx-2">
                {" "}
                Employer{" "}
              </Link>{" "}
              or a{" "}
              <Link to="/signup/benefitprovider" className="mx-2">
                {" "}
                Benefits Provider{" "}
              </Link>
            </p>
          </div>
        </div>
      </Form> */}
    </div>
  );
}

// export default connect(null, mapDispatch)(SignInForm);
export default SignInForm;
