import React, { useState } from "react";
import style from "../style/project.module.css";
import { Menu, Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import Message from "./Message";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import { SmileOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { replyToTicket, getActiveTicket } from "../redux/actions/dashboard";
import moment from "moment";
import { CLOSING } from "ws";

export default function SupportMessages() {
  const [showEmoji, setShowEmoji] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [supportMessages, setMessages] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const { activeTicket } = useSelector((state) => state.dashboard);

  const dispatch = useDispatch();

  const sendMessage = () => {
    if (message) {
      setMessage("");
      // dispatch(
      //   replyToTicket({
      //     ticket: activeTicket._id,
      //     message,
      //   })
      // );
      setSelectedFiles([]);
      var objDiv = document.getElementById("chatContainer");
      objDiv.scrollTop = objDiv.scrollHeight;
      setMessages([
        ...supportMessages,
        { message, user: user._id, createdAt: moment().format() },
      ]);
    }
  };
  React.useEffect(() => {
    setMessages(activeTicket.thread);
    var objDiv = document.getElementById("chatContainer");
    objDiv.scrollTop = objDiv.scrollHeight;
  }, [activeTicket]);

  React.useEffect(() => {
    // dispatch(getActiveTicket({ ticket: activeTicket._id }));
  }, []);
  return (
    <div className={style.chatSection}>
      <div className={style.header}>
        <div className={"my-2"}>
          <h6>
            {user.firstName} {user.lastName}{" "}
          </h6>
        </div>
      </div>
      <div className={style.chatRoom} id="chatContainer">
        {supportMessages.map((item, index) => (
          <Message
            key={index}
            content={item.message}
            right={!item.admin}
            userName={
              !item.admin ? `${user.firstName} ${user.lastName}` : "Admin"
            }
            time={item.createdAt}
          />
        ))}
      </div>
      <div className={style.chatBox}>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(event) => {
            if (event.keyCode === 13) sendMessage();
          }}
        />
        <div className={style.actionBtn}>
          <div className="d-flex align-items-center">
            {showEmoji ? (
              <Picker
                size={24}
                set={"apple"}
                style={{ position: "absolute", bottom: "30px" }}
                title=""
                onClick={(val) => {
                  setMessage(`${message} ${val.native} `);
                  setShowEmoji(false);
                }}
              />
            ) : (
              ""
            )}

            <div className={style.smile}>
              <SmileOutlined onClick={() => setShowEmoji(!showEmoji)} />
            </div>
            <label htmlFor="file">
              <CloudUploadOutlined />{" "}
              {selectedFiles.length > 0
                ? `${selectedFiles.length} files selected`
                : ""}
            </label>
            <input
              type="file"
              name=""
              id="file"
              className="d-none"
              multiple
              onChange={(e) => {
                // console.log(e.target.files);
                setSelectedFiles(e.target.files);
              }}
            />
          </div>
          <button onClick={() => sendMessage()}>Send</button>
        </div>
      </div>
    </div>
  );
}
