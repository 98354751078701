import Auth from "./auth";
import Benefits from "./benefits";
import Resouces from "./resources";
import { combineReducers } from "redux";
import Dasboard from "./dashboard";
import cardModal from "./cardModal";
import intro from "./intro";
import serviceReducer from "./service";

var reducers = combineReducers({
  auth: Auth,
  benefits: Benefits,
  service: serviceReducer,
  resources: Resouces,
  dashboard: Dasboard,
  cardModal,
  intro,
});

export default (state, action) => {
  if (action.type === "LOGOUT") {
    state = {};
  }

  return reducers(state, action);
};
