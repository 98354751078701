import React, { useState } from "react";
import style from "../style/Header.module.css";
import { Menu, Dropdown, Badge, Popover } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import "antd/dist/antd.css";
import Feedback from "./headerModal/Feedback";
import Support from "./headerModal/Support";
import { useDispatch, useSelector } from "react-redux";
import { switchEmployer, setCurrency } from "../redux/actions/auth";
import { Spin, Space } from "antd";
import { BellOutlined } from "@ant-design/icons";
import Upgrade from "./modal/Upgrade";
import moment from "moment";

const mapState = (state) => ({
  user: state.auth.user,
  dashboard: state.auth.dashboard,
});

function Header({ user, dashboard }) {
  let subscriptionType = useSelector(
    (state) => state?.auth?.user?.subscription?.type
  );
  const userType = useSelector((state) => state?.auth?.user?.type);

  const [supportModal, setSupportModal] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [switchView, setSwitchView] = useState(false);
  const [show, setShow] = useState(true);
  const [upgradeModal, setUpgradeModal] = useState(false);

  let notifications = useSelector((state) => state.auth.notifications);
  const [headNotes, setHeadNotes] = useState(notifications);

  const history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    setHeadNotes([
      ...headNotes,
      {
        title: "Climate Benefits",
        body: "Welcome to Climate Benefits! We’re really excited to work together to combat climate change with your organization. We use these push notifications to send you periodic updates about the Climate Benefits ecosystem.",
      },
    ]);
  }, []);

  const sendView = (view) => {
    // dispatch(switchEmployer());
    if (view == "employee") {
      history.push("/employee");
    } else {
      history.push("/employer");
    }
    setSwitchView(true);
    setTimeout(() => {
      setSwitchView(false);
    }, 2000);
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a
          href="#"
          onClick={() =>
            history.push(
              `${
                user.type == "employee"
                  ? "/employee"
                  : user.type == "employer"
                  ? "/employer"
                  : "/benefitprovider"
              }/support`
            )
          }
        >
          Support
        </a>
      </Menu.Item>
      <Menu.Item key="1">
        <a href="#" onClick={() => setFeedbackModal(true)}>
          Feedback
        </a>
      </Menu.Item>

      {user.employerAccount || user.type == "employer" || user.adminAccess ? (
        <Menu.Item key="2">
          <a
            href="#"
            onClick={() =>
              sendView(user.type == "employee" ? "employer" : "employee")
            }
          >
            {user.type == "employee"
              ? "Switch to Employer View"
              : "Switch to Employee View"}
          </a>
        </Menu.Item>
      ) : (
        ""
      )}

      <Menu.Item key="3">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            window.open("https://docs.climatebenefits.com/", "_blank");
          }}
        >
          Documentation
        </a>
      </Menu.Item>
      <Menu.Item key="4">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            localStorage.removeItem("auth_token");
            dispatch({ type: "LOGOUT" });
            history.push("/signin");
          }}
        >
          Log Out
        </a>
      </Menu.Item>
    </Menu>
  );

  const notificationMenu = (
    <Menu>
      {headNotes.map(({ title, body, createdAt }) => (
        <Menu.Item
          key="0"
          style={{
            borderBottom: "1px solid gainsboro",
            padding: "10px 15px",
          }}
        >
          <h2 style={{ fontSize: "1.3em" }}>{title}</h2>
          <p
            style={{
              maxWidth: "300px",
              whiteSpace: "normal",
              overflow: "hidden",
              maxHeight: "65px",
              fontSize: "0.9em",
              lineHeight: "13px",
              marginBottom: "5px",
            }}
          >
            {body}
          </p>
          <span style={{ fontSize: "0.7em" }}>
            {moment(createdAt).fromNow()}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      {switchView ? (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            height: "100vh",
            backgroundColor: "rgba(255,255,255,0.9)",
            zIndex: "99999999999",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Space size="middle">
            <Spin size="large" tip="Switching View..." />
          </Space>
        </div>
      ) : (
        ""
      )}

      <Feedback
        feedbackModal={feedbackModal}
        setFeedbackModal={(item) => setFeedbackModal(item)}
      />
      {/* <Support
        supportModal={supportModal}
        setSupportModal={(item) => setSupportModal(item)}
      /> */}
      <div className={style.Header}>
        <Link className={style.logo} to={`/${user.type}`}>
          <div></div>
          <h6>Climate Benefits</h6>
        </Link>
        <div className="d-flex">
          <p className="mb-0 mr-3">
            {dashboard.points !== null && dashboard.points !== undefined
              ? `${dashboard.points} Points`
              : ""}
          </p>

          {/* {subscriptionType == "trial" || userType == "employee" ? (
            <Link
              className='mb-0 mr-3 upgradeLink introBtn'
              onClick={() => {
                dispatch({ type: "OPEN_INTRO" });
              }}>
              Tutorial
            </Link>
          ) : (
            ""
          )} */}

          {/* {subscriptionType == "trial" ? (
            <>
              <Link
                className='mb-0 mr-3 upgradeLink'
                onClick={() => {
                  setUpgradeModal(true);
                }}>
                Upgrade
              </Link>
              <Upgrade
                upgradeModal={upgradeModal}
                setUpgradeModal={setUpgradeModal}
              />
            </>
          ) : (
            ""
          )}
 */}
          <Dropdown
            overlay={notificationMenu}
            trigger={["hover"]}
            className="mr-5"
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <div style={{ position: "relative" }}>
                <BellOutlined
                  style={{ marginLeft: "0.7em", fontSize: "1em" }}
                />
              </div>
            </a>
          </Dropdown>

          <Dropdown overlay={menu} trigger={["click"]}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              {user.firstName} {user.lastName}
              <DownOutlined
                style={{ marginLeft: "0.5em", fontSize: "0.6em" }}
              />
            </a>
          </Dropdown>
        </div>
      </div>
    </>
  );
}

export default connect(mapState, null)(Header);
