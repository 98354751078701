import React, { useState } from "react";
import style from "../style/ClientBenefits.module.css";
import { useSelector } from "react-redux";
import moment from "moment";

export default function Payouts() {
  const transactions = useSelector((state) => state.auth.transactions);
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return (
    <div>
      <h1 className={`mb-2 ${style.mainHeading}`}>Payouts </h1>
      <h5 className="mb-4">
        Climate Benefits will automatically send you funds to your account on
        the 15th of each month. You can keep track of payouts on the table
        below.
      </h5>
      <div className="pb-5">
        <table className={style.subscription_table}>
          <colgroup>
            <col width="10%" />
            <col width="25%" />
            <col width="25%" />
            <col width="25%" />
          </colgroup>
          <thead>
            <tr>
              <th>#</th>
              <th>Transaction Date</th>
              <th>Received</th>
              <th>Paid</th>
              <th>Processing Fee</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{moment(item.createdAt).format("MM/DD/YYYY")}</td>
                <td>{formatter.format(item.credit)}</td>
                <td>{formatter.format(item.debit)}</td>
                <td>
                  {item.credit
                    ? formatter.format((item.credit / 0.9) * 0.1)
                    : "$0"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
