import Types from "../constants/dashboard";
import emailTemplate from "./testTemplate.json";
var defaultState = {
  template: emailTemplate,
  carbonMeasures: {},
  tickets: [],
  activeTicket: null,
};

var authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.SET_ACTIVE_TICKET: {
      let newState = Object.assign({}, state);
      newState.activeTicket = action.payload;
      return newState;
    }

    case Types.SET_TICKETS: {
      let newState = Object.assign({}, state);
      newState.tickets = [...newState.tickets, action.payload];
      return newState;
    }

    case Types.SET_CARBON_MEASURES: {
      let newState = Object.assign({}, state);
      newState.carbonMeasures = action.payload;
      return newState;
    }

    case Types.SET_TEMPLATE: {
      let newState = Object.assign({}, state);
      newState.template = action.payload;
      return newState;
    }
    case Types.DELETE_TICKET: {
      let newState = Object.assign({}, state);
      newState.tickets = action.payload;
      return newState;
    }

    default:
      return state;
  }
};
export default authReducer;
