import React, { useState } from "react";
import { Select, Input, Button, Form, Modal, Slider, Spin } from "antd";
import benefitstyle from "../style/ClientBenefits.module.css";
import { sendBenefitFeedback } from "../redux/actions/benefits";
import { useDispatch } from "react-redux";

export default function ContributionFeedback({
  visible,
  setVisible,
  selectedBenefit,
  amountDonated,
}) {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const { moneyToCarbonRatio, _id } = selectedBenefit;
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    // await dispatch(sendBenefitFeedback({ ...values, _id }));
    setLoading(false);
    setVisible(false);
  };

  return (
    <Modal
      title="Feedback"
      visible={visible}
      onOk={() => {}}
      onCancel={() => setVisible(false)}
    >
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <div className="row">
          <div className="col-md-12">
            <h2 className="text-center" style={{ fontSize: "1.2em" }}>
              Congratulations on taking action on Climate Benefits! Your
              contribution is going to have a direct impact on climate change.
            </h2>
          </div>
          <div className="col-md-12 mt-4 mb-4">
            <h3 style={{ fontSize: "1.1em" }}>
              Your contribution will remove {moneyToCarbonRatio * amountDonated}{" "}
              pounds of carbon dioxide from the atmosphere.
            </h3>
          </div>

          <div className={`col-md-12`}>
            <Form.Item
              labelCol={{ span: 24 }}
              label="On a scale from 1 - 10, how would you rate this carbon removal project?"
              name="score"
            >
              <Slider
                min={0}
                max={10}
                defaultValue={0}
                className="mt-5"
                style={{ marginTop: "-2em" }}
              />
            </Form.Item>
          </div>
          <div className={`col-md-12`}>
            <Form.Item
              labelCol={{ span: 24 }}
              label="What did you think about this climate project?"
              name={"feedback"}
              rules={[
                {
                  required: true,
                  message: `Please enter your message`,
                },
              ]}
            >
              <TextArea rows={1} className={benefitstyle.input} />
            </Form.Item>
          </div>
          <div className="col-md-12">
            {loading ? (
              <div className="ml-3 my-3 mr-0">
                <Spin />
              </div>
            ) : (
              <button type="submit" className="primaryBtn">
                Submit
              </button>
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
}
