import React, { useState } from "react";
import { Modal, Spin, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { purchaseService } from "../../redux/actions/benefits";

export default function PurchaseServiceModal({ visible, setVisible, data }) {
  const dispatch = useDispatch();

  const userType = useSelector((state) => state?.auth?.user?.type);
  const paymentMethod = useSelector(
    (state) => state?.auth?.user?.paymentMethods
  );
  const { title, price, _id } = data;
  //states
  const [loading, setLoading] = useState(false);

  const triggerAnimation = (time) => {
    setTimeout(
      () =>
        window.confetti({
          particleCount: 1000,
          spread: 200,
          origin: { y: 0.6 },
        }),
      time
    );
  };

  const onSubmit = async () => {
    setLoading(true);

    if (userType == "employer" && paymentMethod?.length == 0) {
      Modal.error({ content: "You have not added a payment method " });
      return;
    }
    if (userType == "benefitprovider") {
      purchaseService({ userType: userType, serviceId: _id });
    }

    const result = await dispatch(
      purchaseService({ userType: userType, serviceId: _id })
    );
    if (result) {
      triggerAnimation(100);
      triggerAnimation(1500);
      triggerAnimation(2800);
      triggerAnimation(4300);
      triggerAnimation(6000);
    }

    setLoading(false);
    setVisible(false);
  };

  return (
    <>
      <Modal
        title={`Purchase  ${title}`}
        visible={visible}
        onOk={() => {}}
        onCancel={() => {
          setVisible(false);
        }}
        width={700}>
        <div className='row'>
          <p className='col-md-12 font-weight-bold'>${price}</p>

          <div className='col-md-12 mt-2 mb-4'>
            Once you purchase, you'll be introduced via email to the service
            provider. Additionally, an Account Manager will reach out within 24
            hours to start the process.
          </div>
          <br />
          <br />
          <div className='col-md-12'>
            {loading ? (
              <div className='ml-3 my-3 mr-0'>
                <Spin />
              </div>
            ) : (
              <button
                type='submit'
                className='primaryBtn'
                onClick={() => onSubmit()}>
                Pay Now
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
