import React, { useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import MainContent from "../components/MainContent";
import { MenuUnfoldOutlined } from "@ant-design/icons";

export default function DashboardLayout({ Data }) {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const sidebarData = Data[1].sidebarData;
  const pageTitle = Data[0].SignUpFormData.pageTitle;

  return (
    <>
      <div>
        <Header pageTitle={pageTitle} />
        <div className="d-flex">
          <Sidebar sidebarVisible={sidebarVisible} sidebarData={sidebarData} />
          <MainContent Data={Data} />
          <button
            className="primaryBtn ToggleBtn"
            onClick={() => setSidebarVisible(true)}
          >
            <MenuUnfoldOutlined />
          </button>
          <div
            className={`overlay ${sidebarVisible ? "show" : ""}`}
            onClick={() => setSidebarVisible(false)}
          ></div>
        </div>
      </div>
    </>
  );
}
