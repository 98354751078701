import React from "react";
import style from "../style/signupForm.module.css";
import { Link } from "react-router-dom";
import { Form, Input, Button, Checkbox } from "antd";
import { getToken, onMessageListener } from "../firebase";

export default function SignUpForm({ data, onSignup }) {
  const onFinish = async (values) => {
    const token = await getToken();
    onSignup({ ...values, deviceToken: token ? token : "not" });
  };

  const onFinishFailed = (errorInfo) => {};
  return (
    <div className={style.SignUpForm}>
      <h2>{data.title}</h2>
      {data.subTitle ? <h2>{data.subTitle}</h2> : ""}
      <Form
        name='basic'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <div className='row'>
          <div className='col-md-6'>
            <Form.Item
              label=''
              name='firstName'
              rules={[
                {
                  required: true,
                  message: "Please input your First Name!",
                },
              ]}>
              <Input placeholder='First Name' />
            </Form.Item>
          </div>
          <div className='col-md-6'>
            <Form.Item
              label=''
              name='lastName'
              rules={[
                {
                  required: true,
                  message: "Please input your Last Name!",
                },
              ]}>
              <Input placeholder='Last Name' />
            </Form.Item>
          </div>
          <div className='col-md-12'>
            <Form.Item
              label=''
              name='email'
              rules={[
                {
                  required: true,
                  message: "Please input your work email!",
                },
              ]}>
              <Input placeholder='Work Email' />
            </Form.Item>
          </div>
          <div className='col-md-6'>
            <Form.Item
              label=''
              name='password'
              rules={[
                {
                  required: true,
                  message: "Please input your passsord!",
                },
              ]}>
              <Input placeholder='Password' type='password' />
            </Form.Item>{" "}
          </div>
          <div className='col-md-6'>
            <Form.Item
              label=''
              name='confirmPassword'
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two passwords that you entered do not match!"
                    );
                  },
                }),
              ]}>
              <Input placeholder='Confirm Password' type='password' />
            </Form.Item>{" "}
          </div>
          <div className='col-md-12 text-right'>
            {data.pageTitle == "employer" ? (
              <button className='primaryBtn mb-4' type='submit'>
                {data.button}
                <img src={`/images/${data.image}`} alt='' className='ml-3' />
              </button>
            ) : (
              <button type='submit' className='primaryBtn mb-4'>
                Sign Up
              </button>
            )}

            <Link to={`/signin`}>Already have an account? Sign In.</Link>
          </div>
        </div>
      </Form>{" "}
    </div>
  );
}
