import React, { useState } from "react";
import { Input, Form, Modal, Radio, Slider, Spin } from "antd";
import SupportMessages from "./SupportMessages";
import { useSelector } from "react-redux";

export default function Feedback({ visible, setVisible }) {
  const [loading, setLoading] = useState(false);
  const { TextArea } = Input;
  const { activeTicket } = useSelector((state) => state.dashboard);
  return activeTicket ? (
    <Modal
      title={`Support Ticket ${activeTicket.ticketId}`}
      visible={visible}
      onOk={() => {}}
      onCancel={() => setVisible(false)}
      footer={""}
    >
      <SupportMessages />
    </Modal>
  ) : (
    ""
  );
}
