import React, { useState } from "react";
import style from "../style/ClientBenefits.module.css";
import { SearchOutlined } from "@ant-design/icons";
import MainCard from "../components/MainCard";
import CardDetail from "../components/CardDetail";
import { connect, useSelector } from "react-redux";
import { setActiveBenefit, allocateFund } from "../redux/actions/benefits";
import {
  addBenefitToEmployer,
  removeBenefitFromEmployer,
  selectAllBenefits,
} from "../redux/actions/auth";
import { useHistory } from "react-router-dom";
import { Select, Input, Button, Form, Modal, Radio, message } from "antd";
import AddBenefit from "../components/modal/AddBenefit";
import AllocateFunds from "../components/modal/AllocateFunds";
import Involved from "../components/modal/Involved";
import ContributionFeedback from "../components/ContributionFeedback";
import _ from "lodash";
import { InfoCircleOutlined, DollarCircleOutlined } from "@ant-design/icons";
import { InputNumber, Tooltip } from "antd";
import { Tabs } from "antd";
import { currencies, ConvertCurrency } from "../utils";
const { TabPane } = Tabs;

const mapState = (state) => ({
  benefits: state.benefits.benefits,
  user: state.auth.user,
  fundsLeft: state.auth.dashboard.funds,
});

const mapDispatch = (Dispatch) => ({
  setActiveBenefit: (data) => Dispatch(setActiveBenefit(data)),
  addBenefitToEmployer: (data) => Dispatch(addBenefitToEmployer(data)),
  removeBenefitFromEmployer: (data) =>
    Dispatch(removeBenefitFromEmployer(data)),

  // allocateFund: (data) => Dispatch(allocateFund(data)),
  selectAllBenefits: (data) => Dispatch(selectAllBenefits(data)),
});

const { Option } = Select;

function Benefits({
  benefits,
  user,
  setActiveBenefit,
  addBenefitToEmployer,
  allocateFund,
  fundsLeft,
  removeBenefitFromEmployer,
  selectAllBenefits,
}) {
  const [filter, setFilter] = useState("");
  const [order, setOrder] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [allocateModal, setAllocateModal] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [involvedModal, setInvolvedModal] = useState(false);
  const [addBenefitModal, setAddBenefitModal] = useState(false);
  const [selectedBenefit, setSelectedBenefit] = useState(false);
  const [amountDonated, setAmountDonated] = useState(false);
  let rates = useSelector((state) => state.auth.currencyRates.rates);


  const history = useHistory();
  function handleChange(value) {
    setFilter(value);
  }
  const pageTitle = user.type;
  let tempBenefits = benefits;
  if (searchVal)
    tempBenefits = tempBenefits.filter((item) =>
      item.title.toLowerCase().includes(searchVal.toLowerCase())
    );
  if (filter && filter !== "All")
    tempBenefits = tempBenefits.filter((item) => item.type == filter);
  if (order) tempBenefits = _.sortBy(tempBenefits, "industry", order);

  const onFinish = async (value) => {
    // await allocateFund({ ...value, benefitId: selectedBenefit._id });
    setAmountDonated(value.amount);
    setFeedbackModal(true);

    return;
  };

  const renderCard = (card) => {
    return (
      <div className="col-xl-6" style={{ marginBottom: "30px" }}>
        <MainCard
          Data={card}
          imgRoute={"benefits"}
          button={
            user.type == "employer"
              ? card.selected
                ? "Selected"
                : "Select"
              : user.type == "employee"
              ? card.type == "Volunteering Opportunities"
                ? "Volunteer"
                : "Allocate Funds"
              : false
          }
          onButtonClick={(modalType) => {
            if (user.type == "employer") {
              if (card.selected)
                Modal.confirm({
                  title: "Deselect Benefit",
                  content:
                    "This benefit will now disappear for your employees. You can always re-select it when you want.",
                  okText: "Confirm",
                  onOk() {
                    removeBenefitFromEmployer(card._id);
                  },
                });
              else {
                if (card.type == "Advocacy")
                  Modal.confirm({
                    title: "Select Benefit",
                    content:
                      "You’ve selected an advocacy organization. It will now appear as an organization that your employees can choose to donate to. If they contribute, your environmental impact dashboard will be updated.",
                    okText: "Confirm",
                    onOk() {
                      addBenefitToEmployer(card._id);
                    },
                  });
                else if (card.type == "Volunteering Opportunities")
                  Modal.confirm({
                    title: "Select Benefit",
                    content:
                      "You’ve selected an volunteering organization. It will now appear as an organization that your employees can learn more about and get involved with.",
                    okText: "Confirm",
                    onOk() {
                      addBenefitToEmployer(card._id);
                    },
                  });
                else if (card.type == "Products & Services")
                  Modal.confirm({
                    title: "Select Benefit",
                    content:
                      "You’ve selected a benefit that is a product or service. It will now appear as an organization that your employees can learn more about and get involved with.",
                    okText: "Confirm",
                    onOk() {
                      addBenefitToEmployer(card._id);
                    },
                  });
                else
                  Modal.confirm({
                    title: "Select Benefit",
                    content:
                      "You’ve selected a benefit that is a carbon offset provider. It will now appear as an organization that your employees can choose to donate to. If they contribute, your environmental impact dashboard will be updated.",
                    okText: "Confirm",
                    onOk() {
                      addBenefitToEmployer(card._id);
                    },
                  });
              }
            } else {
              setSelectedBenefit(card);
              if (card.type == "Volunteering Opportunities") {
                window.open(card.url, "_blank");
                return;
              }
              if (modalType == "Allocate Funds") {
                setAllocateModal(true);
              } else {
                setInvolvedModal(true);
              }
            }
          }}
          userType={user.type}
          setDetailVisible={() => {
            setActiveBenefit(card);
            history.push(`/${user.type}/benefits/detail`);
          }}
        />
      </div>
    );
  };

  return (
    <div style={{ marginBottom: "50px" }}>
      <>
        <Involved
          benefitId={selectedBenefit._id}
          involvedModal={involvedModal}
          setInvolvedModal={(item) => setInvolvedModal(item)}
        />
        <AllocateFunds
          allocateModal={allocateModal}
          setAllocateModal={(item) => setAllocateModal(item)}
          onFinish={onFinish}
        />
        <AddBenefit
          addBenefitModal={addBenefitModal}
          setAddBenefitModal={(item) => setAddBenefitModal(item)}
        />
        <ContributionFeedback
          selectedBenefit={selectedBenefit}
          visible={feedbackModal}
          setVisible={setFeedbackModal}
          amountDonated={amountDonated}
        />
        <div
          className={`d-lg-flex align-items-center justify-content-between flex-wrap mb-3 ${style.ClientBenefits}`}
        >
          <h1 className="d-flex align-items-center mb-0">
            <span>
              {pageTitle == "benefitprovider"
                ? "My Submitted Climate Benefits"
                : "Available Climate Benefits"}
            </span>
            <div className="ml-3">
              {pageTitle == "employer" ? (
                <Tooltip
                  overlayStyle={{ whiteSpace: "pre-line" }}
                  title="At Climate Benefits, we partner with carbon removal projects,
              advocacy organizations, volunteering opportunities, and
              eco-friendly products & services. Each project is transparent
              about the amount of carbon removed from the atmosphere. We
              purchase these carbon credits upfront and then make them available
              for our customers."
                >
                  <InfoCircleOutlined style={{ color: "black" }} />
                </Tooltip>
              ) : (
                ""
              )}
            </div>
          </h1>
          <div className="d-md-flex mt-5 mt-md-0">
            {pageTitle == "employer" ? (
              <>
                <button
                  onClick={() => {
                    Modal.confirm({
                      title: "Select All Benefits",
                      content:
                        "This will select all available benefits on Climate Benefits and make them available to your employees",
                      okText: "Confirm",
                      async onOk() {
                        const response = await selectAllBenefits();
                        if (true) message.success("All Benefits Selected");
                      },
                    });
                  }}
                  className="primaryBtn mt-0 w-100 mr-md-2 mb-3 mb-md-0"
                  style={{ minWidth: "170px" }}
                >
                  Select All Benefits
                </button>
                <button
                  onClick={() => setAddBenefitModal(true)}
                  className="primaryBtn mt-0 w-100 mr-md-2 mb-3 mb-md-0"
                  style={{ minWidth: "250px" }}
                >
                  Add Your Own Benefit
                </button>
              </>
            ) : (
              ""
            )}

            <Input
              placeholder="Search Benefits"
              prefix={<SearchOutlined />}
              value={searchVal}
              onChange={(e) => setSearchVal(e.target.value)}
              style={{ minWidth: "250px" }}
              className="mb-3 mb-md-0"
            />
          </div>
        </div>
        {pageTitle == "employer" ? "" : ""}
        {pageTitle == "employee" ? (
          <p>
            You have{" "}
            {fundsLeft
              ? ConvertCurrency(user.details.currency, rates, fundsLeft)
              : 0}{" "}
            left to allocate this month from your employer.
          </p>
        ) : (
          ""
        )}

        <Tabs defaultActiveKey="1">
          <TabPane tab="Carbon Removal" key="1">
            <div className="row mx-0">
              {tempBenefits
                .filter((item) => item.type == "Carbon Removal")
                .map((card) => renderCard(card))}
            </div>
          </TabPane>
          <TabPane tab="Advocacy" key="2">
            <div className="row mx-0">
              {tempBenefits
                .filter((item) => item.type == "Advocacy")
                .map((card) => renderCard(card))}
            </div>
          </TabPane>
          <TabPane tab="Volunteering Opportunities" key="4">
            <div className="row mx-0">
              {tempBenefits
                .filter((item) => item.type == "Volunteering Opportunities")
                .map((card) => renderCard(card))}
            </div>
          </TabPane>
          <TabPane tab="Products & Services" key="5">
            <div className="row mx-0">
              {tempBenefits
                .filter((item) => item.type == "Products & Services")
                .map((card) => renderCard(card))}
            </div>
          </TabPane>
        </Tabs>
      </>
    </div>
  );
}

export default connect(mapState, mapDispatch)(Benefits);
