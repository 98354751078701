import React, { useState } from "react";
import styles from "../style/subscriptionPlan.module.css";
import SubscriptionNumber from "./SubscriptionNumber";
import { changeLeaderboard } from "../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Switch, Tooltip } from "antd";
import { InfoCircleOutlined, DollarCircleOutlined } from "@ant-design/icons";
import moment from "moment";
export default function LeaderBoard() {
  const user = useSelector((state) => state.auth.user);

  const [points, setPoints] = useState(user.details.points);
  const [leaderboard, setLeaderboard] = useState(user.details.leaderboard);
  const [tutorial, setTutorial] = useState(user.details.tutorial ?? false);
  const dispatch = useDispatch();

  const onFinish = async () => {
    // const result = await dispatch(changeLeaderboard({ points, leaderboard }));
    // if (result) {
    if (true) {
      Modal.success({
        content: "Your engagement settings have been saved!",
      });
    }
  };

  const onFinishFailed = () => {};

  return (
    <div>
      <div
        className='row'
        style={{
          width: "100%",
          maxWidth: "700px",
          marginBottom: "2em",
        }}>
        <div className={`col-md-12`}>
          <Form.Item label='Points' name={"points"}>
            <div>
              <Switch
                style={{ marginRight: "1em" }}
                defaultChecked={points}
                onChange={setPoints}
              />
              <Tooltip title='By turning on Points, Employees will receive 1 point for every dollar contributed through Climate Benefits. This includes both Employer and Employee contributions.'>
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Tooltip>
            </div>
          </Form.Item>
        </div>
        <div className={`col-md-12`}>
          <Form.Item label='Leaderboard' name={"leaderboard"}>
            <div>
              <Switch
                style={{ marginRight: "1em" }}
                defaultChecked={leaderboard}
                onChange={setLeaderboard}
              />
              <Tooltip title='By turning on the Leadrboard, Employees can track their activity against their team members on the home page.'>
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Tooltip>
            </div>
          </Form.Item>
        </div>

        <div className={`col-md-12`}>
          <Form.Item label='Tutorial' name={"tutorial"}>
            <div>
              <Switch
                style={{ marginRight: "1em" }}
                defaultChecked={tutorial}
                onChange={setTutorial}
              />
              <Tooltip title='Here you can Turn on and off the tutorial tour feature.'>
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Tooltip>
            </div>
          </Form.Item>
        </div>

        <div className='col-12'>
          <button className='primaryBtn' onClick={() => onFinish()}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
