import React from "react";
import style from "../style/signUpDesign.module.css";

// "subTitle": "Build a customized climate benefits program to provide donation dollars to your employees so they can contribute to carbon offset projects, track your environmental impact, and access helpful resources all in one place.",

// "subTitle": "Sign up now for free to add your organization so that employees can select your carbon offset project to donate to. If you are not a carbon offset provider, you can add your organization so that employees can select activities and volunteering opportunities to engage with."

export default function SignUpDesign({ signUpStep, data }) {
  return (
    <div className={style.SignUpDesign}>
      <div className='d-flex align-items-center'>
        <div className={style.logo}></div>
        <h6>Climate Benefits</h6>
      </div>
      {signUpStep ? (
        <div style={{ width: "100%", maxWidth: "462px", margin: "auto" }}>
          <div className='mb-5'>
            <h1 className='mb-3'>
              Why do we need to connect to your payroll provider?
            </h1>
            <p>
              We use this integration to automatically fill in your Climate
              Beneifts dashboard with all your employees. From the ‘Team’ page
              you’ll be able to invite employees participating in the benefit.
            </p>
          </div>
          <div>
            <h1 className='mb-3'>When will I be charged?</h1>
            <p>
              We won’t charge you until your employees accept their invite to
              the Climate Benefit. You can control billing information from the
              ‘Settings’ tab on your dashboard. Additionally, we’ll send you
              monthly invoice receipts for your records.
            </p>
          </div>
        </div>
      ) : (
        <div style={{ width: "100%", maxWidth: "495px", margin: "auto" }}>
          {data && data.pageTitle == "employer" ? (
            <>
              <h1>All-In-One Climate Change Software.</h1>
              <p>
                Climate Benefits is all-in-one climate change software for
                businesses. We help customers measure, offset, and report their
                carbon emissions— all while engaging their employees.
              </p>
            </>
          ) : data && data.pageTitle == "provider" ? (
            <>
              <h1>Partner with Climate Benefits.</h1>
              <p>
                We work with carbon removal projects, advocacy organizations,
                volunteering opportunities, and eco-friendly products &
                services. Once approved, we provide tools to track and grow a
                new revenue stream for your business. We only take 10% of each
                transaction.
              </p>
            </>
          ) : (
            <>
              <h1>All-In-One Climate Change Software. </h1>
              <p>
                Climate Benefits is all-in-one climate change software for
                businesses. We help customers measure, offset, and report their
                carbon emissions— all while engaging their employees.
              </p>
            </>
          )}

          <div className='text-center'>
            <img src='/images/signup_design_img.png' alt='' className='' />
          </div>
        </div>
      )}
    </div>
  );
}
