import React, { useState } from "react";
import substyles from "../../style/authStep.module.css";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import styles from "../../style/subscriptionPlan.module.css";
import StripeConnect from "../StripeConnect";
import CardInfo from "../CardInfo";
import { Form, Input, Tooltip, Modal } from "antd";
import { InfoCircleOutlined, DollarCircleOutlined } from "@ant-design/icons";
import { setMonthlyLimit } from "../../redux/actions/auth";
import inputstyle from "../../style/ClientBenefits.module.css";

export default function PaymentMethod() {
  const { user } = useSelector((state) => state.auth);
  const [cardData, setCardData] = useState({ cvc: "", expiry: "", card: "" });
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    // await dispatch(setMonthlyLimit(values));
    Modal.success({ content: "Monthly Stipend added successfully" });
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div className={substyles.container}>
      <h2 className={"mb-2"}>Welcome to Climate Benefits!</h2>
      <h5 className={"mb-5 text-center"}>
        Enter your card details and stipend amount to start. And don't worry,
        you can change this later.{" "}
      </h5>
      <div
        className='d-flex justify-content-center flex-column'
        style={{ width: "650px" }}>
        <div
          className={`${styles.container} mb-5 algin-items-center d-flex`}
          style={{
            cursor: "pointer",
            border: "2px solid #175cff",
            background: "#F1F1F1",
            height: "80px",
            width: "100%",
          }}>
          <CardInfo
            onCardChange={(data) => {
              setCardData(data);
            }}
            cardEdit={false}
            // onCancel={() => console.log("connected")}
            cardData={cardData}
            fullWidth={true}
          />
        </div>
        <div
          className={`${styles.container} mb-5 algin-items-center`}
          style={{
            cursor: "pointer",
            border: "2px solid #175cff",
            background: "#F1F1F1",
            height: "80px",
            width: "100%",
          }}>
          <Form
            name='basic'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{ width: "100%" }}
            className='d-flex'>
            <Form.Item
              name={"monthlyFund"}
              style={{ width: "100%", margin: "0" }}>
              <Input
                className={`${inputstyle.input} greyInput`}
                type='number'
                placeholder='Monthly Stipend for Each Employee'
                style={{ border: "none" }}
                prefix={
                  <DollarCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                }
                suffix={
                  <Tooltip title="This is the amount of funds that each employee can contribute per month to Climate Benefits of their choice. Whenever they contribute, your card on file will be charged up to the limit you set. Employees can also contribute out of pocket whenever they'd like. All transactions happen securely via Stripe.">
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
              />
            </Form.Item>
            <button
              className='primaryBtn'
              type='submit'
              style={{ width: "80px" }}>
              Save
            </button>
          </Form>
        </div>
      </div>
    </div>
  );
}
