import Types from "../constants/benefits";

const initialState = { openCardMoadal: false };

const cardModalReducer = (state = initialState, action) => {
  if (action.type === Types.CARD_MODAL_TOGGLE) {
    return { openCardMoadal: action.payload };
  }
  return state;
};

export default cardModalReducer;
