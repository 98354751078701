import React, { useState } from "react";
import SignUpDesign from "../components/SignUpDesign";
import SignInForm from "../components/signInForm";
import ForgetForm from "../components/ForgetForm";
import ResetForm from "../components/resetForm";

export default function ForgetPassword({}) {
  return (
    <div style={{ height: "100vh", backgroundColor: "#ffffff" }}>
      <div className="row h-100">
        <div className="col-md-5 h-100">
          <SignUpDesign />
        </div>
        <div className="col-md-7 h-100">
          <ForgetForm />
        </div>
      </div>
    </div>
  );
}
