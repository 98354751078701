import React, { useState } from "react";
import { useSelector } from "react-redux";
export default function Communications() {
  const user = useSelector((state) => state.auth.user);

  return (
    <div>
      <h3>About Climate Benefits</h3>
      <div className="row mx-0">
        <div
          className="col-10 mt-3 p-3"
          style={{ backgroundColor: "#fff", borderRadius: 15, minHeight: 500 }}
          dangerouslySetInnerHTML={{ __html: user.link ? user.link.about : "" }}
        ></div>
      </div>
    </div>
  );
}
