import Types from "../constants/resources";

var defaultState = {
  resources: [
    {
      selectedBy: [],
      _id: "605905465d953756e5aadd16",
      user: "605596474ea8f3563edcdaec",
      creator: "admin",
      public: true,
      title: "100 solutions to reverse global warming",
      shortDescription:
        'What if we took out more greenhouse gases than we put into the atmosphere? This hypothetical scenario, known as "drawdown," is our only hope of averting climate disaster, says strategist Chad Frischmann.',
      longDescription:
        'What if we took out more greenhouse gases than we put into the atmosphere? This hypothetical scenario, known as "drawdown," is our only hope of averting climate disaster, says strategist Chad Frischmann. In a forward-thinking talk, he shares solutions to climate change that exist today -- conventional tactics like the use of renewable energy and better land management as well as some lesser-known approaches, like changes to food production, better family planning and the education of girls. Learn more about how we can reverse global warming and create a world where regeneration, not destruction, is the rule.\r\n',
      industry: "Videos",
      url: "https://www.youtube.com/watch?v=D4vjGSiRGKY",
      image: "8b2f3230-8b51-11eb-aea5-c58c9b8d7863.png",
      createdAt: "2021-03-22T20:59:50.869Z",
      __v: 0,
      id: "605905465d953756e5aadd16",
    },
    {
      selectedBy: [],
      _id: "60a7e034ba4d15336ef7b615",
      user: "605596474ea8f3563edcdaec",
      creator: "admin",
      public: true,
      title: "Aerial",
      shortDescription:
        "Aerial is the easiest and most accurate way to manage your carbon footprint. We track your emissions, give you simple ways to take action, and offer exclusive insights from credible science writers on how to live more sustainably. Aerial makes climate action easy and fun.",
      longDescription:
        '<p>Ready to take charge of your carbon footprint?<br>&nbsp;</p><p>Aerial is the easiest &amp; most accurate way to manage your emissions.</p><p>&nbsp;</p><p>How it works:<br>&nbsp;</p><p>→ <strong>Track Your Carbon Footprint</strong></p><p>We accurately calculate how much carbon you emit from flights, rideshare, and rail. Support for more emissions sources coming soon.</p><p>&nbsp;</p><p>→ <strong>Offset Your Emissions with our Climate Subscription</strong></p><p>By contributing to verified forest conservation projects and innovative carbon capture initiatives, you help balance your emissions.</p><p>&nbsp;</p><p>→ <strong>Make an Impact</strong></p><p>Compete with friends to save trees and help the planet.</p><p>&nbsp;</p><p>→ <strong>Get Informed</strong></p><p>Gain actionable climate insights from our exclusive content in the Discover tab. New climate action Stories have been added.</p><p>&nbsp;</p><h4>Learn more:</h4><ul><li><a href="https://docs.google.com/document/d/1fuLox_RM8zR9tBkX_mcQGGZ2J_mWlxk81v2FqvZ0-8o">Mobile App, Aerial, Raises $2M Seed Round to Help Users Effortlessly Calculate and Offset Their Carbon Emissions</a></li><li><a href="https://docs.google.com/document/d/1dvzKWjqvUZ12bQAbD5m-FbVKRhzm3UohYucRX8Hjb44">Aerial, the Sustainability Platform, Launches Carbon Offset Tool for NFTs</a></li><li><a href="https://docs.google.com/document/d/1MX4NWzGGpAbLNk0XMMUGQ_kZVj11X0vCOeTSypkbBsA">Aerial Launches New Mobile App to Help Users Effortlessly Track and Offset Their Carbon Footprint</a></li></ul><p>&nbsp;</p><figure class="image"><img src="https://www.aerial.is/static/images/linkpreview.png"></figure>',
      industry: "App",
      url: "https://www.aerial.is/",
      image: "5c415ff0-c53c-11eb-992d-153137291c62.jpg",
      createdAt: "2021-05-21T16:30:44.907Z",
      __v: 0,
      id: "60a7e034ba4d15336ef7b615",
    },
    {
      selectedBy: [],
      _id: "605a972e5d953756e5aadd23",
      user: "605596474ea8f3563edcdaec",
      creator: "admin",
      public: true,
      title: "Arcadia Power",
      shortDescription:
        "Arcadia is a nationwide home energy platform providing clean energy and savings.",
      longDescription:
        "Arcadia is a nationwide home energy platform providing clean energy and savings. Arcadia Power is remaking the customer utility experience from the ground-up, and personalizing it. They offer customers seamless monthly billing, access to wind energy, community solar programs, and on-bill financing of energy efficient products.",
      industry: "Products",
      url: "https://arcadia.com",
      image: "461694e0-8c41-11eb-aea5-c58c9b8d7863.png",
      createdAt: "2021-03-24T01:34:38.819Z",
      __v: 0,
      id: "605a972e5d953756e5aadd23",
    },
    {
      selectedBy: [],
      _id: "605a91b95d953756e5aadd1f",
      user: "605596474ea8f3563edcdaec",
      creator: "admin",
      public: true,
      title: "CleanTechnica News",
      shortDescription:
        "CleanTechnica is a cleantech-focused website that explores innovations and technologies to create a more sustainable world. ",
      longDescription:
        "CleanTechnica is a cleantech-focused website that explores innovations and technologies to create a more sustainable world. Topics commonly covered by this online publication include solar power, clean transport, wind power, energy efficiency and energy storage, as well as the broader effects of climate change. The site also aims to debunk misconceptions about clean energy and to provide reliable information on the latest breakthroughs in sustainable solutions.\r\n\r\n",
      industry: "News",
      url: "https://cleantechnica.com/",
      image: "d8918040-8c3d-11eb-aea5-c58c9b8d7863.png",
      createdAt: "2021-03-24T01:11:21.929Z",
      __v: 0,
      id: "605a91b95d953756e5aadd1f",
    },
    {
      selectedBy: [],
      _id: "605a92be5d953756e5aadd20",
      user: "605596474ea8f3563edcdaec",
      creator: "admin",
      public: true,
      title: "Climate Central",
      shortDescription:
        "An independent organization of leading scientists and journalists researching and reporting the facts about our changing climate and its impact on the public.",
      longDescription:
        "Climate Central uses science, big data, and technology to generate thousands of local storylines and compelling visuals that make climate change personal and show what can be done about it. We address climate science, sea level rise, extreme weather, energy, and related topics. We collaborate widely with TV meteorologists, journalists, and other respected voices to reach audiences across diverse geographies and beliefs. \r\n\r\nOur Climate Matters program provides a network of more than 790 local TV meteorologists in nearly 90% of U.S. media markets with the data and graphics to tell stories about the links between weather and climate change. Its sister program, Climate Matters in the Newsroom, goes beyond the TV weatherdesk to provide print, digital, TV and radio journalists working on all beats with materials that help them cover locally relevant stories on climate impacts and solutions. \r\n\r\nOur Sea Level Rise program assesses and maps coastal threats globally, researches the intersection of social and physical vulnerability on U.S. coasts, and is pioneering a new generation of online tools and visualizations. Coastal stakeholders from government, business, NGOs and academia use our research and materials extensively.\r\n\r\nThrough our Partnerships Journalism initiative, we work with local media outlets to co-report, co-produce, and co-publish data-rich feature stories on climate solutions and impacts.",
      industry: "News",
      url: "https://www.climatecentral.org/",
      image: "740d45e0-8c3e-11eb-aea5-c58c9b8d7863.png",
      createdAt: "2021-03-24T01:15:42.783Z",
      __v: 0,
      id: "605a92be5d953756e5aadd20",
    },
    {
      selectedBy: [],
      _id: "605902de5d953756e5aadd15",
      user: "605596474ea8f3563edcdaec",
      creator: "admin",
      public: true,
      title: "Elon Musk's Break Down of Climate Change",
      shortDescription:
        "Elon Musk breaks down climate change for students at The Sorbonne in Paris (France's Harvard) right before the historic COP21 climate change conference in which *all* nations signed the now historic Paris Agreement in 2015.",
      longDescription:
        "Elon Musk breaks down climate change for students at The Sorbonne in Paris (France's Harvard) right before the historic COP21 climate change conference in which *all* nations signed the now historic Paris Agreement in 2015 to reduce carbon emissions below 2C, and preferably under 1.5C.  ",
      industry: "Videos",
      url: "https://www.youtube.com/watch?v=xKCuDxpccYM",
      image: "429f9010-8b50-11eb-aea5-c58c9b8d7863.jpeg",
      createdAt: "2021-03-22T20:49:34.954Z",
      __v: 0,
      id: "605902de5d953756e5aadd15",
    },
    {
      selectedBy: [],
      _id: "605900565d953756e5aadd13",
      user: "605596474ea8f3563edcdaec",
      creator: "admin",
      public: true,
      title: "NOAA Climate Education Resource Collection",
      shortDescription:
        "A collection of resources to help people understand the science behind climate change. ",
      longDescription:
        "The National Oceanic and Atmospheric Administration under the U.S. Department of Commerce has a collection of resources to help people understand the science behind climate change. ",
      industry: "Government",
      url: "https://www.noaa.gov/education/resource-collections/climate",
      image: "4d48f190-8b51-11eb-aea5-c58c9b8d7863.jpeg",
      createdAt: "2021-03-22T20:38:46.620Z",
      __v: 0,
      id: "605900565d953756e5aadd13",
    },
    {
      selectedBy: [],
      _id: "605a98895d953756e5aadd24",
      user: "605596474ea8f3563edcdaec",
      creator: "admin",
      public: true,
      title: "Solstice Enerfy",
      shortDescription:
        "Solstice offers a turnkey solution for the community solar industry.",
      longDescription:
        "Solstice offers a turnkey solution for the community solar industry. We educate and connect communities to a solar garden, create financial innovations that expand access to all Americans, and provide a seamless digital platform for community solar.",
      industry: "Products",
      url: "https://solstice.us/",
      image: "e78ca850-8c41-11eb-aea5-c58c9b8d7863.png",
      createdAt: "2021-03-24T01:40:25.046Z",
      __v: 0,
      id: "605a98895d953756e5aadd24",
    },
    {
      selectedBy: [],
      _id: "605901e55d953756e5aadd14",
      user: "605596474ea8f3563edcdaec",
      creator: "admin",
      public: true,
      title: "StarTalk Podcast: Coronavirus and Climate Change",
      shortDescription:
        "On this episode of StarTalk Radio, Neil deGrasse Tyson is investigating the link between coronavirus and climate change alongside comic co-host Chuck Nice and expert guests. ",
      longDescription:
        "On this episode of StarTalk Radio, Neil deGrasse Tyson is investigating the link between coronavirus and climate change alongside comic co-host Chuck Nice and expert guests. How has coronavirus impacted climate change? Can methods used to track climate change be used to track the spread of coronavirus? We explore these topics and a lot more. \r\n\r\nOur first guest is Gavin Schmidt, PhD, Director of the NASA Goddard Institute for Space Studies. To start, we get a little background on CO2 emissions. How has the air quality changed over the current quarantined period? You’ll learn how the decrease of car and air travel have impacted our climate. We explore how climate modeling is similar to modeling the spread of coronavirus. You’ll also learn the benefits of “simple” vs “complex” modeling. Gavin tells us why models are fundamental to scientific enterprise. \r\n\r\nThen we welcome our second guest, Katharine Hayhoe, PhD, director of the Climate Center at Texas Tech University. She also has her own YouTube channel called @Global Weirding with Katharine Hayhoe. Katharine explains how coronavirus made the jump from animals to humans (zoonosis) and why this is not the first, or last, time it will happen. You’ll hear why the current pandemic is like a microcosm of climate change. \r\n\r\nWe investigate the role that government has played in this pandemic so far and what governments can learn from dealing with this situation. Katharine explains how this pandemic and climate change are similar in the fact that they both impact the poorest people the hardest. \r\n\r\nWe take a look at the spread of conspiracy theories during this pandemic and how they come from the same places that spread climate change conspiracies. Lastly, you’ll also hear about climatefeedback.org, a website run by a network of scientists to bring you truthful climate change news. All that, plus, Katharine shares a hopeful message for the future.\r\n",
      industry: "Videos",
      url: "https://www.youtube.com/watch?v=kSjTsInyveE",
      image: "8718f890-8b4f-11eb-aea5-c58c9b8d7863.jpeg",
      createdAt: "2021-03-22T20:45:25.019Z",
      __v: 0,
      id: "605901e55d953756e5aadd14",
    },
    {
      selectedBy: [],
      _id: "605a93f65d953756e5aadd21",
      user: "605596474ea8f3563edcdaec",
      creator: "admin",
      public: true,
      title: "The New York Times’ Climate and Environment Section",
      shortDescription:
        "The New York Times’ comprehensive climate and environment section covers topics such as biofuels, tidal and wave power, natural gas, geothermal, hydro, nuclear, coal, oil, solar and wind. ",
      longDescription:
        " The New York Times’ comprehensive climate and environment section covers topics such as biofuels, tidal and wave power, natural gas, geothermal, hydro, nuclear, coal, oil, solar and wind. As a trusted national news source, The Times’ coverage of these topics provides readers with access to the most recent news and information about issues related to climate change.",
      industry: "News",
      url: "https://www.nytimes.com/section/climate",
      image: "2daa0ec0-8c3f-11eb-aea5-c58c9b8d7863.png",
      createdAt: "2021-03-24T01:20:54.190Z",
      __v: 0,
      id: "605a93f65d953756e5aadd21",
    },
    {
      selectedBy: [],
      _id: "605909db5d953756e5aadd17",
      user: "605596474ea8f3563edcdaec",
      creator: "admin",
      public: true,
      title: "Why Climate Change Denial Still Exists In The U.S.",
      shortDescription:
        "Despite overwhelming scientific evidence, some American politicians continue to deny that climate change exists, while others question the severity of its impact.",
      longDescription:
        "Despite overwhelming scientific evidence, some American politicians continue to deny that climate change exists, while others question the severity of its impact. But public opinion is shifting, and today even oil and gas companies publicly admit that climate change demands action. So why does climate denialism continue to influence U.S. politics? Here's a look into who is funding the movement, and why denial is mainly a U.S. problem.",
      industry: "Videos",
      url: "https://www.youtube.com/watch?v=f1rxv1yPQrc",
      image: "4635d960-8b54-11eb-aea5-c58c9b8d7863.png",
      createdAt: "2021-03-22T21:19:23.639Z",
      __v: 0,
      id: "605909db5d953756e5aadd17",
    },
    {
      selectedBy: [],
      _id: "605a95405d953756e5aadd22",
      user: "605596474ea8f3563edcdaec",
      creator: "admin",
      public: true,
      title: "World Health Organization",
      shortDescription:
        "The World Health Organization (WHO) is a specialized agency of the United Nations (UN) that is concerned with international public health. ",
      longDescription:
        "The World Health Organization (WHO) is a specialized agency of the United Nations (UN) that is concerned with international public health. This includes the challenges of climate change and the ways it affects the livelihood and physical health of individuals in different communities across the world. The climate change section of the organization’s website includes fact sheets, data, guidelines and information on what WHO is doing to address important issues related to the effects of climate change.",
      industry: "News",
      url: "https://www.who.int/health-topics/climate-change",
      image: "f24b6df0-8c3f-11eb-aea5-c58c9b8d7863.png",
      createdAt: "2021-03-24T01:26:24.080Z",
      __v: 0,
      id: "605a95405d953756e5aadd22",
    },
  ],
  active_resource: null,
};

var authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.SET_RESOURCES: {
      let newState = Object.assign({}, state);
      newState.resources = action.payload;
      return newState;
    }
    case Types.SET_ACTIVE_RESOURCE: {
      let newState = Object.assign({}, state);
      newState.active_resource = action.payload;
      return newState;
    }

    default:
      return state;
  }
};
export default authReducer;
