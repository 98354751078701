import { useSelector } from "react-redux";

export const currencies = { USD: "$", EUR: "€", GBP: "£" };

const GetConvertedCurrency = (baseAmount, convertToCurrency, rates) => {
  return Number.parseFloat(baseAmount * rates[convertToCurrency]).toFixed(2);
};

export const ConvertCurrency = (currency = "USD", rates, val) => {
  return `${currencies[currency]} ${
    currency == "USD" ? val : GetConvertedCurrency(val, currency, rates)
  }`;
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
