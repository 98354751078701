import React from "react";
import style from "../style/steps.module.css";
import { Link } from "react-router-dom";
import ClientBenefits from "../pages/ClientBenefits";
import employerData from "../data/employer.json";
import { connect } from "react-redux";

const mapState = (state) => ({
  dashboard: state.auth.dashboard,
  user: state.auth.user,
  employees: state.auth.employees,
  benefits: state.benefits.benefits,
});

function Steps({ employees, benefits }) {
  return (
    <>
      <div className={style.Steps}>
        <h1>Welcome to Climate Benefits!</h1>
        {/* {employees.length == 0 || benefits.length == 0 ? (
          <>
            <h6>
              Once you complete our simple 3 step on-boarding, this page will be
              your benefits dashboard with data and analytics.
            </h6>
            <h6>
              If you need help getting set up, contact us. We’re here to help.{" "}
            </h6>
            <div
              className="d-flex flex-wrap align-items-center"
              style={{ marginLeft: "-1em" }}
            >
              <div className={style.stepCard}>
                <h3>Step 1</h3>
                <p>Choose Benefits for your organization.</p>
                <Link to="/employer/benefits" className="primaryBtn">
                  Get Started
                </Link>
              </div>
              <div className={style.stepCard}>
                <h3>Step 2</h3>
                <p>Add a payment method and set a monthly limit for employees.</p>
                <Link to="/employer/settings" className="primaryBtn">
                  Get Started
                </Link>
              </div>
              <div className={style.stepCard}>
                <h3>Step 3</h3>
                <p>Invite your team members via email.</p>
                <Link to="/employer/teams" className="primaryBtn">
                  Get Started
                </Link>
              </div>
            </div>
          </>
        ) : ( */}
        <ClientBenefits SignUpFormData={employerData[0].SignUpFormData} />
        {/* )} */}
      </div>
    </>
  );
}

export default connect(mapState, null)(Steps);
