import React, { useState } from "react";
import { Input, Form, Modal, Spin } from "antd";
import benefitstyle from "../style/ClientBenefits.module.css";
import { chargeCarbonFootprint } from "../redux/actions/benefits";
import { useDispatch } from "react-redux";
export default function PurchaseAnalysisModal({ visible, setFeedbackModal }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { TextArea } = Input;

  const onFinish = async (values) => {
    setLoading(true);
    // let result = await dispatch(chargeCarbonFootprint());

    // if (result)
    if (true)
      Modal.success({
        title: "",
        content:
          "Your Carbon Footprint Analysis purchase was successful. We'll follow up within 24 hours to start the process.",
      });
    else
      Modal.error({
        title: "",
        content: "Unable to charge your card right now.",
      });

    setLoading(false);
    setFeedbackModal(false);
  };

  return (
    <Modal
      title='Purchase a Full Carbon Footprint Analysis'
      visible={visible}
      onOk={() => {}}
      onCancel={() => setFeedbackModal(false)}>
      <Form
        name='basic'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}>
        <div className='row'>
          <div className={`col-md-12`}>
            <p>
              Custom Carbon Footprint Analysis Price: <b>$5000</b>{" "}
            </p>
            <p>
              Once you purchase, an Account Manager will reach out within 24
              hours to start the process. We'll work with you over the course of
              4 weeks to build a 10 page Carbon Footprint report based on 50+
              data points including bank transactions, vendors, customers,
              office space, and several others.
            </p>
          </div>

          <div className='col-md-12'>
            {loading ? (
              <div className='ml-3 my-3 mr-0'>
                <Spin color='white' />
              </div>
            ) : (
              <button type='submit' className='primaryBtn'>
                Purchase
              </button>
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
}
