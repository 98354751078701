import React, { useState } from "react";
import style from "../style/MainCard.module.css";
import environment from "../api/environment";
import { Popover, Button, Modal, message } from "antd";
import { removeRecurring } from "../redux/actions/benefits";
import { useDispatch, useSelector } from "react-redux";
import PurchaseServiceModal from "../components/modal/PurchaseServiceModal";
import { useHistory } from "react-router-dom";
import { setActiveService } from "../redux/actions/benefits";

const imgBasePath = "https://admin.climatebenefits.com";

export default function ServicesCard({
  Data,
  setDetailVisible,
  imgRoute,
  button,
  onButtonClick,
  onboarding,
  maxHeight,
  modalType,
}) {
  const {
    id,
    image,
    title,
    shortDescription,
    type,
    recurringCharge,
    _id,
    outsideImage,
    moneyToCarbonRatio,
  } = Data;

  const [popVisible, setPopVisible] = React.useState(false);
  const user = useSelector((state) => state.auth.user);
  const [offsetModal, setOffsetModal] = React.useState(false);
  const [purchaseModal, setPurchaseModal] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const content = (
    <div>
      <button
        onClick={() => {
          setPopVisible(false);
          Modal.confirm({
            title: "Remove Recurring",
            content: (
              <div>
                <p>This benefit will not be recurring anymore.</p>
              </div>
            ),
            async onOk() {
              await dispatch(removeRecurring(_id));

              Modal.success({
                content: "We've stopped the recurring contribution.",
              });
            },
          });
        }}
        className='primaryBtn bg-danger'>
        Remove Recurring
      </button>
    </div>
  );

  return (
    <div
      className={style.MainCard}
      // style={{ maxHeight: maxHeight ? maxHeight : "315px" }}
    >
      {outsideImage}

      <PurchaseServiceModal
        visible={purchaseModal}
        setVisible={setPurchaseModal}
        data={Data}
      />
      <img
        src={
          outsideImage
            ? image
              ? image
              : "/cbLogo.png"
            : `${imgBasePath}/img/${imgRoute}/${image}`
        }
        alt=''
        className={`${onboarding ? "w-100" : ""}`}
        style={{
          objectFit: outsideImage ? (image ? "cover" : "contain") : "cover",
        }}
        onClick={() => {
          setDetailVisible();
        }}
      />
      <div className={`${style.cardContent} ${onboarding ? "w-100" : ""}`}>
        <div>
          <h3
            onClick={() => {
              setDetailVisible();
            }}>
            {title}
          </h3>
          <p>{shortDescription} </p>
        </div>
        <div
          style={{ position: "absolute", right: "21px", fontWeight: "bold" }}>
          {/* {type} */}
          {recurringCharge ? (
            <Popover content={content} visible={popVisible} trigger='click'>
              <img
                src='/images/clock.svg'
                className='ml-3'
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  setPopVisible(true);
                }}
                style={{ width: "15px", height: "15px", cursor: "pointer" }}
              />
            </Popover>
          ) : (
            ""
          )}
        </div>

        <div className={style.cardFooter}>
          <button
            className='primaryBtn mr-1'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setPurchaseModal(true);
            }}>
            Purchase
          </button>
          <button
            className='primaryBtn ml-1'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              dispatch(setActiveService(Data));
              history.push(`/${user.type}/services/detail`);
            }}>
            Read More
          </button>
        </div>
      </div>
    </div>
  );
}
