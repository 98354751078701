import React, { useState } from "react";
import SignUpDesign from "../components/SignUpDesign";
import SignInForm from "../components/signInForm";

export default function SignIn({}) {
  const [forgetPassword, setForgetPassword] = useState();

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: "#ffffff",
        overflow: "hidden",
      }}
    >
      <div className="row h-100">
        <div className="col-lg-5 d-none h-100 d-md-flex">
          <SignUpDesign data={false} />
        </div>
        <div className="col-lg-7 h-100">
          <SignInForm />
        </div>
      </div>
    </div>
  );
}
