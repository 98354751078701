import React, { Component, useEffect } from "react";
import SignUpRoutes from "./routes/SignUpRoutes";
import DashboardRoutes from "./routes/DashboardRoutes";
import apiRequest from "./api/apiRequest";
import {
  setUser,
  fetchEmployees,
  getNotifications,
  fetchTransactions,
  fetchBenefitFeedback,
  getCurrency,
} from "./redux/actions/auth";
import { fetchResources } from "./redux/actions/resources";
import { fetchBenefits } from "./redux/actions/benefits";
import { getMeasureData } from "./redux/actions/dashboard";
import ExternalReport from "./pages/ExternalReport";
import { connect } from "react-redux";
import { useHistory, Route, Switch } from "react-router-dom";
import RestrictedRoute from "./components/RestrictedRoute";
import { useState } from "react";
import { getToken, onMessageListener } from "./firebase";
import { Button, Row, Col, Toast } from "react-bootstrap";
import { notification } from "antd";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const mapDispatch = (dispatch) => ({
  // setUser: (data) => dispatch(setUser(data)),
  // fetchBenefits: () => dispatch(fetchBenefits()),
  // fetchEmployees: () => dispatch(fetchEmployees()),
  // fetchResources: () => dispatch(fetchResources()),
  // getNotifications: () => dispatch(getNotifications()),
  // fetchTransactions: () => dispatch(fetchTransactions()),
  // fetchBenefitFeedback: () => dispatch(fetchBenefitFeedback()),
  // getCurrency: (val) => dispatch(getCurrency(val)),
  // getMeasureData: () => dispatch(getMeasureData()),
});

function App(props) {
  const [showLoader, setShowLoader] = React.useState(false);
  const history = useHistory();

  const [show, setShow] = useState(false);

  onMessageListener()
    .then((payload) => {
      notification.open({
        message: payload.notification.title,
        description: payload.notification.body,
        onClick: () => {},
      });
    })
    .catch((err) => {
      // console.log("failed: ", err)
    });

  const fetchUser = async () => {
    const user = localStorage.getItem("auth_token");
    const token = await getToken();

    if (user) {
      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
      }, 4000);
      apiRequest({
        method: "POST",
        url: "/users/me",
        data: {
          deviceToken: token ? token : "not",
        },
      })
        .then(async (res) => {
          const user = res.data.data;
          if (user) {
            // await props.getMeasureData();
            // await props.getCurrency(user.details.currency);
            // await props.fetchBenefits();
            // await props.fetchResources();
            if (user.type == "employer") {
              // props.fetchEmployees();
              // props.fetchBenefitFeedback();
            }
            if (user.type == "employer" || user.type == "benefitprovider")
              // props.fetchTransactions();

              // props.getNotifications();
              // props.setUser(user);

              history.push(`/${user.type}`);
          } else {
            localStorage.removeItem("auth_token");
            history.push(`/signin`);
          }
        })
        .catch(() => {
          localStorage.removeItem("auth_token");
          history.push(`/signin`);
        });
    } else {
      if (!window.location.pathname.includes("resetpassword"))
        history.push(`/signin`);
    }
  };

  useEffect(() => {
    if (
      window.location.pathname.includes("signup") ||
      window.location.pathname.includes("external-report")
    ) {
      // console.log("goes to the signup page");
    } else fetchUser();
  }, []);

  return (
    <>
      {showLoader ? (
        <div className="main_loader">
          <img src="/images/Earth_animated.gif" alt="" />
        </div>
      ) : (
        ""
      )}

      <Switch>
        <RestrictedRoute
          path={["/employer", "/employee", "/benefitprovider"]}
          component={DashboardRoutes}
        />
        <Route path="/external-report/:userEmail" component={ExternalReport} />
        <Route path="/" component={SignUpRoutes} />
      </Switch>
    </>
  );
}

export default connect(null, mapDispatch)(App);
