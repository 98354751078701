import React from "react";
import style from "../style/project.module.css";
import moment from "moment";

export default function Message({ right, content, userName, time, files }) {
  return (
    <div className={` ${style.messageItem} ${right ? style.rightSide : ""}`}>
      <div>
        {userName.split(" ")[0][0]}
        {userName.split(" ")[1] ? userName.split(" ")[1][0] : ""}
      </div>
      <div>
        <h5>
          {userName} <span>{moment(time).format("MMM DD, hh:mm A")} </span>
        </h5>
        <p>{content}</p>
        {/* <a
          href="#"
          onClick={() => {
            files.map((item) => {
              window.open(item, "_blank");
            });
          }}
        >
          <small>{files.length > 0 ? `${files.length} file(s)` : ""} </small>
        </a> */}
      </div>
    </div>
  );
}
