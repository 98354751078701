import React from "react";
import CardInfo from "../CardInfo";
import { Modal, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { setCardModal } from "../../redux/actions/cardModal";

const CreditCardModal = () => {
  const cardModal = useSelector((state) => state?.cardModal?.openCardMoadal);
  const dispatch = useDispatch();

  const handleOk = () => {
    // setCardModal(false);
    dispatch(setCardModal(false));
  };

  const handleCancel = () => {
    dispatch(setCardModal(false));

    // setCardModal(false);
  };
  return (
    <>
      <Modal
        bodyStyle={{
          height: "auto",
          paddingBottom: "80px",
        }}
        title='Please Add a Payment Method'
        visible={cardModal}
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}>
        <CardInfo />
      </Modal>
    </>
  );
};
export default CreditCardModal;
