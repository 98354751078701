import React from "react";
import style from "../style/MainContent.module.css";
import { Switch, Route, Redirect } from "react-router-dom";
import ClientBenefits from "../pages/ClientBenefits";
import Benefits from "../pages/Benefits";
import Teams from "../pages/Teams";
import Resources from "../pages/Resources";
import Setting from "../pages/Setting";
import Submit from "../pages/Submit";
import Steps from "../components/Steps";
import BenefitDetail from "../pages/BenefitDetail";
import Communications from "../pages/Communications";
import About from "../pages/About";
import Payouts from "../pages/Payouts";
import Reports from "../pages/Reports";
import Measure from "../pages/Measure";
import Support from "../pages/Support";
import ClimateServices from "../pages/ClimateServices";
import ClimateServiceDetail from "../pages/ClimateServiceDetail";

export default function MainContent({ Data }) {
  return (
    <div className={style.MainContent}>
      <Switch>
        <Route exact path='/employer' render={() => <Steps />} />
        <Route exact path='/employer/measure' render={() => <Measure />} />
        <Route path='/employer/teams' render={() => <Teams />} />

        <Route
          path='/employer/communication'
          render={() => <Communications />}
        />

        <Route path='/employer/reports' render={() => <Reports />} />
        <Route
          exact
          path='/employer/settings'
          render={() => (
            <Setting
              SignUpFormData={Data[0].SignUpFormData}
              Data={Data[4].settingData}
            />
          )}
        />

        <Route path='/employee/about' render={() => <About />} />
        <Route
          exact
          path='/employee'
          render={() => (
            <ClientBenefits SignUpFormData={Data[0].SignUpFormData} />
          )}
        />

        <Route
          exact
          path='/employee/resources'
          render={() => (
            <Resources resourceCardData={Data[3].resourceCardData} />
          )}
        />
        <Route
          exact
          path='/employee/settings'
          render={() => (
            <Setting
              SignUpFormData={Data[0].SignUpFormData}
              Data={Data[5].settingData}
            />
          )}
        />

        <Route
          exact
          path='/benefitprovider'
          render={() => <Submit Data={Data[4].submitData} type='provider' />}
        />

        <Route
          exact
          path='/benefitprovider/edit'
          render={() => <Submit Data={Data[4].submitData} type='provider' />}
        />

        <Route
          exact
          path={[
            "/benefitprovider/benefits/detail",
            "/employer/benefits/detail",
            "/employee/benefits/detail",
          ]}
          render={() => <BenefitDetail type='provider' />}
        />

        <Route
          exact
          path={["/employee/resources/detail"]}
          render={() => <BenefitDetail type='provider' page={"resources"} />}
        />

        <Route
          exact
          path={[
            "/benefitprovider/benefits",
            "/employer/benefits",
            "/employee/benefits",
          ]}
          render={() => <Benefits benefitCardData={Data[2].benefitCardData} />}
        />
        <Route
          exact
          path={["/benefitprovider/services", "/employer/services"]}
          render={() => <ClimateServices />}
        />

        <Route
          exact
          path={[
            "/benefitprovider/services/detail",
            "/employer/services/detail",
          ]}
          render={() => <ClimateServiceDetail />}
        />

        <Route
          exact
          path={[
            "/benefitprovider/support",
            "/employer/support",
            "/employee/support",
          ]}
          render={() => <Support />}
        />

        <Route
          exact
          path='/benefitprovider/settings'
          render={() => <Setting Data={Data[3].settingData} type='provider' />}
        />
        <Route
          exact
          path='/benefitprovider/payouts'
          render={() => <Payouts />}
        />
      </Switch>
    </div>
  );
}
