import React from "react";
import style from "../style/cardDetail.module.css";
import { useHistory } from "react-router-dom";
import environment from "../api/environment";
import { deleteBenefit } from "../redux/actions/benefits";
import { connect } from "react-redux";
import { Modal, message } from "antd";
import EditBenefit from "./EditBenefit";

const dispatchState = (dispatch) => ({
  deleteBenefit: (data) => dispatch(deleteBenefit(data)),
});
const imgBasePath = "https://admin.climatebenefits.com";

function CardDetail({ data, imgRoute, userType, deleteBenefit, page }) {
  const [isModalVisible, setModalVisible] = React.useState(false);
  const { title, image, shortDescription, longDescription, type, url } = data;
  const history = useHistory();
  return (
    <div>
      <EditBenefit
        setModalVisible={setModalVisible}
        isModalVisible={isModalVisible}
      />
      <h1 className={`${style.mainHeading} ${page ? "" : "mb-2"}`}>
        {page
          ? `Available Resources > ${title}`
          : `Available Climate Benefits > ${title}`}
      </h1>
      {type == "Volunteering Opportunities" ? (
        ""
      ) : page || type === "Advocacy" ? (
        <h3 style={{ marginBottom: "1em", fontSize: "1em" }}>
          This is an advocacy organization. Contributing to this benefit goes to
          the organization in general, not specifically to carbon removal.
        </h3>
      ) : (
        <h3 style={{ marginBottom: "1em", fontSize: "1em" }}>
          For every $1 contributed, {title} will remove{" "}
          {data.moneyToCarbonRatio} tons of carbon dioxide or equivalent from
          the atmosphere.
        </h3>
      )}
      <div className={style.CardDetail}>
        <img
          src={`${imgBasePath}/img/${page ? page : imgRoute}/${image}`}
          style={{ width: "220px", height: "300px" }}
          alt=""
        />
        <div className={style.cardContent}>
          <div>
            <h3>{title}</h3>
            <p
              className={style.detailContent}
              dangerouslySetInnerHTML={{ __html: `${longDescription}` }}
            ></p>
            {type === "Advocacy" && userType == "benefitprovider" ? (
              <div>
                <h5>Users Involved</h5>
                <ul>
                  {data.involved.map((item) => (
                    <li>{item.email} </li>
                  ))}
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
          <div>
            {userType == "benefitprovider" ? (
              <>
                <button
                  className="primaryBtn align-self-center mr-3"
                  onClick={() => setModalVisible(data)}
                >
                  Edit Benefit
                </button>
                <button
                  className="primaryBtn align-self-center"
                  onClick={() => {
                    Modal.confirm({
                      title: "Delete Benefit",
                      content: (
                        <div>
                          <p>
                            This benefit will be deleted permanently if you hit
                            Ok.
                          </p>
                        </div>
                      ),
                      onOk() {
                        deleteBenefit(data._id);
                        message.success("Benefit Deleted Successfully");
                        history.push(`/${userType}/benefits`);
                      },
                    });
                  }}
                >
                  Delete Benefit
                </button>
              </>
            ) : (
              <a
                href={url}
                target="_blank"
                className="primaryBtn align-self-center"
              >
                {page ? "View Resource" : "Learn More"}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default connect(null, dispatchState)(CardDetail);
