import React from "react";
import { Modal, Button } from "antd";
import SubscriptionPlan from "../SubscriptionPlan";

const Upgrade = ({ upgradeModal, setUpgradeModal }) => {
  const showModal = (visible) => {
    setUpgradeModal(visible);
  };

  const handleOk = () => {
    setUpgradeModal(false);
  };

  const handleCancel = () => {
    setUpgradeModal(false);
  };
  return (
    <>
      <Modal
        bodyStyle={{
          backgroundColor: "#e5e5e5",
          height: "auto",
          paddingBottom: "100px",
        }}
        title='Upgrade Your Subscription Plan'
        visible={upgradeModal}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}>
        <SubscriptionPlan />
      </Modal>
    </>
  );
};

export default Upgrade;
