import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import employerData from "../data/employer.json";
import employeeData from "../data/employee.json";
import providerData from "../data/provider.json";
import Signup from "../pages/SignUp";
import Signin from "../pages/SignIn";
import ResetPassword from "../pages/ResetPassword";
import ForgetPassword from "../pages/ForgetPassword";
export default class SignUpRoutes extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/resetpassword/:id" component={ResetPassword} />
          <Route
            exact
            path="/signup/employer"
            render={() => <Signup Data={employerData} />}
          />
          <Route exact path="/signin" render={() => <Signin />} />
          <Route exact path="/forgot" render={() => <ForgetPassword />} />
          <Route
            exact
            path="/signup/benefitprovider"
            render={() => <Signup Data={providerData} />}
          />
        </Switch>
      </div>
    );
  }
}
