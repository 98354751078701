import React from "react";
import { Editor } from "react-draft-wysiwyg";
import style from "../style/ClientBenefits.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const AboutEditor = ({ editorState, setEditorState }) => {
  const onChange = async (value) => {
    setEditorState(value);
  };
  return (
    <>
      <Editor
        editorState={editorState}
        toolbarClassName='toolbarClassName'
        wrapperClassName='wrapperClassName'
        editorClassName={style.editor}
        onEditorStateChange={(value) => {
          onChange(value);
        }}
        stripPastedStyles
        ariaLabel='draftEditor'
      />
    </>
  );
};

export default AboutEditor;
