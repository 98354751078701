import React, { useState } from "react";
import substyles from "../../style/authStep.module.css";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import styles from "../../style/subscriptionPlan.module.css";
import SubscriptionNumber from "../SubscriptionNumber";
import { changePricingPlan } from "../../redux/actions/auth";
import { Modal } from "antd";

export default function PricePlan() {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [plan, setPlan] = useState("trial");
  // const user = {
  //   firstName: "hameed",
  // };

  const onFinish = async (value) => {
    // const result = await dispatch(changePricingPlan(value));
    setModalVisible(false);
    // if (result) {
    if (true) {
      Modal.success({
        content:
          "Congratulations! You can track the number of available Employee seats from the Team page. Your Account Manager will reach out within 24 hours.",
      });
    }
  };

  return (
    <div className={substyles.container}>
      <c />
      <h2 className={"mb-5"}>
        Welcome
        <span style={{ textTransform: "capitalize" }}>{user.firstName}.</span>
        Select one below.
      </h2>
      <SubscriptionNumber
        visible={modalVisible}
        setVisible={setModalVisible}
        plan={plan}
        onFinish={onFinish}
      />
      <div className='d-flex justify-content-center mb-5'>
        {/* Trial Plan */}
        <div
          className={styles.container}
          onClick={() => {
            setPlan("trial");
            Modal.success({
              content: "Congratulations! You are on the trial plan now.",
            });
          }}
          style={{
            cursor: "pointer",
            border: plan == "trial" ? "2px solid #175cff" : "2px solid grey",
            background: "#F1F1F1",
          }}>
          <h3 className='text-center mb-2'>Trial</h3>
          <h5 className='text-center mb-4'>Free</h5>
          <span className='text-center mb-3'>
            You can customize your Climate Benefits experience but not add your
            team.
          </span>
          <button type='submit' className='signUpPrimaryBtn'>
            {plan == "trial" ? "Selected" : "Select"}
          </button>
        </div>

        {/* Starter Plan */}
        <div
          className={styles.container}
          onClick={() => {
            setPlan("starter");
            setModalVisible(true);
          }}
          style={{
            cursor: "pointer",
            border: plan == "starter" ? "2px solid #175cff" : "2px solid grey",
            background: "#F1F1F1",
          }}>
          <h3 className='text-center mb-2'>Starter</h3>
          <h5 className='text-center mb-4'>Paid Monthly</h5>
          <span className='text-center mb-3'>Starts at $149 / month</span>
          <button
            type='submit'
            style={{ marginTop: "3rem" }}
            className='signUpPrimaryBtn'
            onClick={() => {
              setPlan("enterprise");
              setModalVisible(true);
            }}>
            {plan == "starter" ? "Selected" : "Select"}
          </button>
        </div>

        {/* Enterprise Plan */}
        <div
          className={styles.container}
          onClick={() => {
            setPlan("enterprise");
            setModalVisible(true);
          }}
          style={{
            cursor: "pointer",
            border:
              plan == "enterprise" ? "2px solid #175cff" : "2px solid grey",
            background: "#F1F1F1",
          }}>
          <h3 className='text-center mb-2'>Enterprise</h3>
          <h5 className='text-center mb-4'>Paid Annually</h5>
          <span className='text-center mb-3'>Custom Pricing</span>
          <button
            type='submit'
            style={{ marginTop: "3rem" }}
            className='signUpPrimaryBtn'>
            {plan == "enterprise" ? "Selected" : "Select"}
          </button>
        </div>
      </div>
    </div>
  );
}
