import React, { useState } from "react";
import style from "../style/ClientBenefits.module.css";
import { Select } from "antd";
import MainCard from "../components/MainCard";
import CardDetail from "../components/CardDetail";
import { connect } from "react-redux";
import { setActiveBenefit } from "../redux/actions/benefits";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { InfoCircleOutlined, DollarCircleOutlined } from "@ant-design/icons";
import { InputNumber, Tooltip } from "antd";
import { convertCurrency, numberWithCommas } from "../utils";
import { Link } from "react-router-dom";

const mapState = (state) => ({
  dashboard: state.auth.dashboard,
  user: state.auth.user,
  benefits: state.benefits.benefits.filter((item) => item.selected),
  leaderboard: state.benefits.leaderboard,
});

const mapDispatch = (Dispatch) => ({
  setActiveBenefit: (data) => Dispatch(setActiveBenefit(data)),
});

const { Option } = Select;

function ClientBenefits({
  user,
  SignUpFormData,
  dashboard,
  setActiveBenefit,
  benefits,
  leaderboard,
}) {
  const history = useHistory();
  const [filter, setFilter] = useState("");

  function handleChange(value) {
    setFilter(value);
  }

  const infoCardDataEmployer = [
    {
      icon: "employee_icon.svg",
      title: "Participating Employees",
      count: dashboard.employeesParticipating
        ? dashboard.employeesParticipating
        : 0,
    },
    {
      icon: "co2_icon.svg",
      title: "Carbon Removed",
      count: `${
        dashboard.totalOrganizationCarbonImpact
          ? parseFloat(dashboard.totalOrganizationCarbonImpact).toFixed(2)
          : "0"
      } Tons`,
    },
    {
      icon: "co2_icon.svg",
      title: "Carbon Footprint",
      count: `${
        dashboard.carbonFootprint
          ? numberWithCommas(dashboard.carbonFootprint)
          : "0"
      } Tons`,
    },
  ];
  const infoCardDataEmployee = [
    {
      icon: "employee_icon.svg",
      title: "Team Members Participating",
      count: dashboard.teamMembers ? dashboard.teamMembers : 0,
    },
    {
      icon: "co2_icon.svg",
      title: "Personal Carbon Removed",
      count: `${
        dashboard.totalPersonalCarbonImpact
          ? parseFloat(dashboard.totalPersonalCarbonImpact).toFixed(2)
          : "0"
      } Tons`,
    },
    {
      icon: "co2_icon.svg",
      title: "Organization Carbon Removed",
      count: `${
        dashboard.totalOrganizationCarbonImpact
          ? parseFloat(dashboard.totalOrganizationCarbonImpact).toFixed(2)
          : "0"
      } Tons`,
    },
  ];
  const { pageTitle } = SignUpFormData;

  let tempBenefits =
    pageTitle == "employee"
      ? dashboard.benefits
        ? dashboard.benefits
        : []
      : benefits;
  if (filter && filter !== "All")
    tempBenefits = tempBenefits.filter((item) => item.type == filter);

  return (
    <div>
      <div className={style.ClientBenefits}>
        <div className="d-flex flex-wrap mb-5">
          {pageTitle == "employee"
            ? infoCardDataEmployee.map((card) => (
                <Tooltip title={card.tooltip} placement="bottom">
                  <div className={`${style.infoCard} mb-2 mb-md-0`}>
                    <img src={`/images/${card.icon}`} alt="" />
                    <div>
                      <p>{card.title}</p>
                      <h6>{card.count}</h6>
                    </div>
                  </div>
                </Tooltip>
              ))
            : infoCardDataEmployer.map((card) => (
                <div className={`${style.infoCard} mb-2 mb-md-0`}>
                  <img src={`/images/${card.icon}`} alt="" />
                  <div>
                    <p>{card.title}</p>
                    <h6>{card.count}</h6>
                  </div>
                </div>
              ))}
        </div>

        {pageTitle == "employee" && leaderboard == true ? (
          <>
            <div className="d-flex align-items-center mb-4">
              <h1 className="d-flex align-items-center">
                <span>Leaderboard</span>
              </h1>
            </div>
            <table className={`${style.subscription_table} w-auto`}>
              <colgroup>
                <col width="20%" />
                <col width="25%" />
                <col width="25%" />
              </colgroup>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Name</th>
                  <th>Points</th>
                </tr>
              </thead>
              <tbody>
                {leaderboard?.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.employee.fullName}</td>
                    <td>{item.points}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          ""
        )}

        <div className="d-flex mt-4 align-items-center justify-content-between mb-4">
          <h1 className="d-flex align-items-center">
            <span>My Climate Benefits </span>
            {pageTitle == "employer" ? (
              <div className="ml-3">
                <Tooltip title="These are the climate benefits you’ve selected to share with your employees.">
                  <InfoCircleOutlined style={{ color: "black" }} />
                </Tooltip>
              </div>
            ) : (
              ""
            )}
          </h1>
          <Select
            defaultValue="Sort By"
            style={{ width: 220 }}
            onChange={handleChange}
          >
            <Option value="All">All</Option>
            <Option value="Carbon Removal">Carbon Removal</Option>
            <Option value="Advocacy">Advocacy</Option>
            <Option value="Volunteering Opportunities">
              Volunteering Opportunities
            </Option>
            <Option value="Products & Services">Products & Services</Option>
          </Select>
        </div>

        <div className="row ">
          {pageTitle == "employee" ? (
            tempBenefits.length == 0 ? (
              <div className="col-12">
                <p>
                  Click on the "Benefits" tab to get started. Once you select
                  specific Climate Benefits, they will appear here.
                </p>
              </div>
            ) : (
              tempBenefits.map((card) => (
                <div className="col-lg-6" style={{ marginBottom: "30px" }}>
                  <MainCard
                    Data={card}
                    imgRoute={"benefits"}
                    userType={user.type}
                    setDetailVisible={() => {
                      setActiveBenefit(card);
                      history.push(`/${user.type}/benefits/detail`);
                    }}
                  />
                </div>
              ))
            )
          ) : (
            tempBenefits.map((card) => (
              <div className="col-lg-6" style={{ marginBottom: "30px" }}>
                <MainCard
                  Data={card}
                  imgRoute={"benefits"}
                  userType={user.type}
                  setDetailVisible={() => {
                    setActiveBenefit(card);
                    history.push(`/${user.type}/benefits/detail`);
                  }}
                />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(mapState, mapDispatch)(ClientBenefits);
