export default {
  SET_BENEFITS: "sets the benefits",
  SET_ACTIVE_BENEFIT: "sets active benefit",
  ADD_BENEFIT: "adds a new benefit",
  SET_LEADERBOARD: "SET_LEADERBOARD",
  REPORT_GET: "REPORT_GET",
  TAGS_GET: "TAGS_GET",
  ALERTS_GET: "ALERTS_GET",
  SET_ACTIVE_SERVICE: "SET_ACTIVE_SERVICE",
  CARD_MODAL_TOGGLE: "CARD_MODAL_TOGGLE",
  EDIT_BENEFIT: "EDIT_BENEFIT",
};
