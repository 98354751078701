import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import style from "../style/ClientBenefits.module.css";

export default function EmployeeFeedbackList() {
  const { feedbacks } = useSelector((state) => state.auth.benefitFeedback);
  return (
    <div className="d-flex justify-content-start mb-5 mt-3 ">
      <table className={style.subscription_table}>
        <colgroup>
          <col width="10%" />
          <col width="25%" />
          <col width="25%" />
          <col width="25%" />
        </colgroup>
        <thead>
          <tr>
            <th>#</th>
            <th>Employee Name</th>
            <th>Feedback</th>
            <th>Rating</th>
            <th>Feeling</th>
          </tr>
        </thead>
        <tbody>
          {feedbacks.map(({ recommendScore, feelings, body, user }, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>
                {user.firstName} {user.lastName}
              </td>
              <td>{body}</td>
              <td>{recommendScore}</td>
              <td>{feelings}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
