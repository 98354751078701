import React, { useState } from "react";
import style from "../style/integeration.module.css";
import { useFinchConnect } from "react-finch-connect";
import environment from "../api/environment";
import apiRequest from "../api/apiRequest";
import { finchConnect } from "../redux/actions/auth";
import { connect } from "react-redux";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function StripeConnect(props) {
  // const user = useSelector((state) => state.auth.user);
  const open = () => {
    apiRequest({
      method: "get",
      url: "/users/stripeconnect/onboard-user",
    }).then((res) => {
      window.open(res.data.data, "_blank");
    });
  };

  const user = { stripeAccount: false };

  return (
    <>
      <div className={style.Integration}>
        <div>
          <span style={{ marginRight: "5em", fontSize: "1.3em" }}>
            Stripe Account
          </span>
          <button onClick={() => open()} className={`primaryBtn`}>
            {user.stripeAccount
              ? user.stripeAccount.detailsSubmitted
                ? "Connected"
                : "Connect"
              : "Connect"}
          </button>
        </div>
      </div>
    </>
  );
}

export default StripeConnect;
