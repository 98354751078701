import React from "react";
import { Select, Input, Button, Form, Modal } from "antd";
import benefitstyle from "../style/ClientBenefits.module.css";

export default function AddEmployee({
  setModalVisible,
  isModalVisible,
  onFinish,
}) {
  const onFinishFailed = (errorInfo) => {};

  const AddFields = [
    { value: "firstName", title: "First Name" },
    { value: "lastName", title: "Last Name" },
    { value: "email", title: "Email" },
  ];
  return (
    <Modal
      footer={null}
      title="Add Employee"
      visible={isModalVisible}
      onOk={() => {
        setModalVisible(false);
      }}
      onCancel={() => setModalVisible(false)}
      width={700}
    >
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="row" style={{ width: "100%", maxWidth: "700px" }}>
          {AddFields.map((item) => (
            <div className={`col-md-6`}>
              <Form.Item
                label=""
                name={item.value}
                rules={[
                  {
                    required: true,
                    message: `Please input your ${item.title.toLowerCase()}!`,
                  },
                ]}
              >
                <Input
                  placeholder={item.title}
                  className={benefitstyle.input}
                />
              </Form.Item>
            </div>
          ))}

          <div className="col-md-12">
            <p className="mt-3 mb-0">
              Clicking Submit will invite this employee via email.
            </p>
            <p className="mb-0">
              You can "Invite" or "Delete" them from the user table.
            </p>

            <button
              type="submit"
              className="primaryBtn"
              style={{ width: "180px" }}
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
