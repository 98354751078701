import Types from "../constants/service";
var defaultState = {
  services: [
    {
      _id: "62c5a990d346cf62ff9a4ce3",
      title: "Sustainability Assessment",
      shortDescription:
        "We'll work with you to identify, measure, and evaluate your company's sustainability initiatives. Delivery includes a 5 page report that can be used for marketing. ",
      longDescription:
        "<p>We'll work with you to identify, measure, and evaluate your company's sustainability initiatives. Delivery includes a 5 page report that can be used for marketing.&nbsp;</p><p>&nbsp;</p><p><strong>Team</strong></p><ul><li>This service is provided by the Climate Benefits team.&nbsp;</li></ul><p>&nbsp;</p><p><strong>Process</strong></p><ul><li>Once you check out, we'll set up a 1 hour introduction call to understand your sustainability goals, review your Climate Benefits dashboard, and understand employee engagement at your company.&nbsp;</li><li>Over the next 4 weeks, we'll meet weekly for an hour to accurately measure and report your carbon emissions. During this period, we'll request specific data including accounting reports, &nbsp;bank transactions, and more to accurately report data.&nbsp;</li></ul><p>&nbsp;</p><p><strong>Outcomes</strong></p><ul><li>The delivery includes a 5 page sustainability report. This report will include a detailed breakdown of your carbon emissions, forecasts to achieve your Net Zero goals, tips for engaging your employee base, and action items to take within Climate Benefits to offset your impact.&nbsp;</li><li>Included in the price is unlimited revisions to this report.&nbsp;</li></ul>",
      price: "5000",
      image: "f54ddb10-fd3f-11ec-8e15-e1bb4bdd65b6.jpg",
      email: "param@climatebenefits.com",
      createdAt: "2022-07-06T15:26:08.198Z",
      __v: 0,
    },
  ],
};

const serviceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.GET_SERVICES: {
      let newState = Object.assign({}, state);
      newState.services = action.payload;
      return newState;
    }
    default:
      return state;
  }
};
export default serviceReducer;
