import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import createStore from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import env from "./api/environment";
import amplitude from "amplitude-js";
import {
  AmplitudeProvider,
  Amplitude,
  LogOnMount,
} from "@amplitude/react-amplitude";

const AMPLITUDE_KEY = "cf41525dc199f45bf38f1a4722e18f90";
Sentry.init({
  dsn: "https://60e7854f1baf4535bf4d51062364db93@o1215723.ingest.sentry.io/6357585",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const store = createStore();
var stripe = loadStripe(env.stripePublishableKey);

ReactDOM.render(
  <AmplitudeProvider
    amplitudeInstance={amplitude.getInstance()}
    apiKey={AMPLITUDE_KEY}>
    <Amplitude>
      <Elements stripe={stripe}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </Elements>
      <LogOnMount eventType='start game' />{" "}
    </Amplitude>
  </AmplitudeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
