import React, { useState } from "react";
import CardDetail from "../components/CardDetail";
import { connect } from "react-redux";
const mapState = (state) => ({
  active_benefit: state.benefits.active_benefit,
  user: state.auth.user,
});

function BenefitDetail({ active_benefit, user, page }) {
  return (
    <div>
      <CardDetail
        data={active_benefit}
        imgRoute={"benefits"}
        userType={user.type}
        page={page}
      />
    </div>
  );
}

export default connect(mapState, null)(BenefitDetail);
