import React, { useState } from "react";
import style from "../style/ClientBenefits.module.css";
import ServicesCard from "../components/ServicesCard";
import CardDetail from "../components/CardDetail";
import { useSelector } from "react-redux";
import _ from "lodash";
import { InfoCircleOutlined, DollarCircleOutlined } from "@ant-design/icons";
import { InputNumber, Tooltip } from "antd";
import { Select, Input, Button, Form, Modal, Radio, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";

function ClimateServices() {
  const services = useSelector((state) => state.service.services);
  const [searchVal, setSearchVal] = useState("");
  let tempBenefits = services;
  if (searchVal)
    tempBenefits = tempBenefits.filter((item) =>
      item.title.toLowerCase().includes(searchVal.toLowerCase())
    );
  return (
    <div>
      <>
        <div className={``}>
          <div
            className={`d-lg-flex align-items-center justify-content-between flex-wrap mb-3`}>
            <h1 className='service-page-title'>Climate Services</h1>

            <div>
              <Input
                placeholder='Search Services'
                prefix={<SearchOutlined />}
                value={searchVal}
                onChange={(e) => setSearchVal(e.target.value)}
                style={{ minWidth: "250px" }}
                className='mb-3 mb-md-0'
              />
            </div>
          </div>
          <h5 className='mb-5'>
            We've partnered with industry leading climate experts to provide you
            a la carte services to supplement your ESG strategy.
          </h5>
        </div>
        {/* {pageTitle == "employer" ? "" : ""}
        {pageTitle == "employee" ? (
          <p>
            You have{" "}
            {fundsLeft
              ? ConvertCurrency(user.details.currency, rates, fundsLeft)
              : 0}{" "}
            left to allocate this month from your employer.
          </p>
        ) : (
          ""
        )}
 */}
        <div className='row mx-0'>
          {tempBenefits?.map((card) => (
            <div
              key={card.id}
              className='col-xl-6'
              style={{ marginBottom: "30px" }}>
              <ServicesCard
                Data={card}
                imgRoute={"services"}
                setDetailVisible={() => {}}
                modalType='services'
              />
            </div>
          ))}
        </div>
      </>
    </div>
  );
}

export default ClimateServices;
