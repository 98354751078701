import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTemplate } from "../redux/actions/dashboard";
export default function EmailEditor() {
  const dispatch = useDispatch();
  const template = useSelector((state) => state.dashboard.template);
  React.useEffect(() => {
    if (document.getElementById("editor-container"))
      document.getElementById("editor-container").remove();

    var para = document.createElement("div"); // Create a <p> element
    para.style.height = "580px";
    para.setAttribute("id", "editor-container");

    document.getElementById("editorOutside").appendChild(para);

    const editor = window.unlayer.createEditor({
      id: "editor-container",
      projectId: 19853,
      displayMode: "email",
      customJS: [``],
      customCSS: ["https://use.fontawesome.com/releases/v5.1.1/css/all.css"],
    });

    editor.loadDesign(template);

    editor.addEventListener("design:updated", function (updates) {
      // Design is updated by the user
      editor.exportHtml((data) => {
        const { design, html } = data;
        // dispatch(addTemplate({ html, design }));
      });
    });
  }, []);
  return (
    <div>
      <div
        className='row mt-3 mx-0'
        id='editorOutside'
        style={{ paddingBottom: "10em", width: "90%", height: "800px" }}></div>
    </div>
  );
}
