import React, { useState } from "react";
import SignUpDesign from "../components/SignUpDesign";
import ResetForm from "../components/resetForm";
import { useLocation } from "react-router-dom";

export default function ResetPassword(props) {
  return props.match.params.id ? (
    <div style={{ height: "100vh", backgroundColor: "#ffffff" }}>
      <div className="row h-100">
        <div className="col-md-5 h-100">
          <SignUpDesign />
        </div>
        <div className="col-md-7 h-100">
          <ResetForm id={props.match.params.id} />
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}
