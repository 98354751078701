import React, { useState } from "react";
import style from "../style/ClientBenefits.module.css";
import { Select, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import MainCard from "../components/MainCard";
import CardDetail from "../components/CardDetail";
import { connect } from "react-redux";
import { setActiveBenefit } from "../redux/actions/benefits";
import { addBenefitToEmployer } from "../redux/actions/auth";
import { useHistory } from "react-router-dom";
import _ from "lodash";
const mapState = (state) => ({
  resources: state.resources.resources,
  user: state.auth.user,
});

const mapDispatch = (Dispatch) => ({
  setActiveBenefit: (data) => Dispatch(setActiveBenefit(data)),
  // addBenefitToEmployer: (data) => Dispatch(addBenefitToEmployer(data)),
});

const { Option } = Select;

function Benefits({ resources, user, setActiveBenefit, addBenefitToEmployer }) {
  // const [filter, setFilter] = useState("");
  const [order, setOrder] = useState("");
  const [searchVal, setSearchVal] = useState("");

  const history = useHistory();
  // function handleChange(value) {
  //   setFilter(value);
  // }

  let tempBenefits = resources;
  if (searchVal)
    tempBenefits = tempBenefits.filter((item) =>
      item.title.toLowerCase().includes(searchVal.toLowerCase())
    );
  // if (filter) tempBenefits = tempBenefits.filter((item) => item.type == filter);
  if (order) tempBenefits = _.sortBy(tempBenefits, "industry", order);

  return (
    <div>
      <>
        <div
          className={`d-lg-flex align-items-center justify-content-between flex-wrap mb-3 ${style.ClientBenefits}`}>
          <div>
            {" "}
            <h1 className='mb-4 mb-sm-0'>Climate Resources</h1>
            <h6 className='mt-2'>
              Learn more about Climate Change with hand picked resources.
            </h6>
          </div>
          <div className='d-flex '>
            <Input
              style={{ width: "300px" }}
              placeholder='Search Resources'
              prefix={<SearchOutlined />}
              value={searchVal}
              onChange={(e) => setSearchVal(e.target.value)}
            />
            {/* <Select
              defaultValue="Industry"
              style={{ width: 120, margin: "0 0.5em" }}
              onChange={(val) => setOrder(val)}
            >
              <Option value="asc">Acending</Option>
              <Option value="desc">Decending</Option>
            </Select>
            <Select
              defaultValue="Sort By"
              style={{ width: 120 }}
              onChange={handleChange}
            >
              <Option value="Donation">Donation</Option>
              <Option value="Activity">Activity</Option>
            </Select> */}
          </div>
        </div>
        <div className='row'>
          {tempBenefits?.map((card) => (
            <div className='col-lg-6' style={{ marginBottom: "30px" }}>
              <MainCard
                Data={card}
                imgRoute={"resources"}
                button={
                  user.type == "employer"
                    ? card.selected
                      ? "Selected"
                      : "Select"
                    : false
                }
                onButtonClick={() => {
                  // addBenefitToEmployer(card._id);
                }}
                userType={user.type}
                setDetailVisible={() => {
                  setActiveBenefit(card);
                  history.push(`/${user.type}/resources/detail`);
                }}
              />
            </div>
          ))}
        </div>
      </>
    </div>
  );
}

export default connect(mapState, mapDispatch)(Benefits);
