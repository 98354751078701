import React from "react";
import style from "../style/cardDetail.module.css";
import environment from "../api/environment";
import { deleteBenefit } from "../redux/actions/benefits";
import { connect } from "react-redux";

const imgBasePath = "https://admin.climatebenefits.com";

const dispatchState = (dispatch) => ({
  deleteBenefit: (data) => dispatch(deleteBenefit(data)),
});

function ServiceDetail({ data, imgRoute, userType, deleteBenefit, page }) {
  const { title, image, shortDescription, longDescription, type, url } = data;

  return (
    <div>
      <h1 className={`${style.mainHeading} ${page ? "" : "mb-2"}`}>
        Available Service
      </h1>

      <div className={style.CardDetail}>
        <img
          src={`${imgBasePath}/img/${page ? page : imgRoute}/${image}`}
          style={{ width: "220px", height: "300px" }}
          alt=''
        />
        <div className={style.cardContent}>
          <div>
            <h3>{title}</h3>
            <p
              className={style.detailContent}
              dangerouslySetInnerHTML={{ __html: `${longDescription}` }}></p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default connect(null, dispatchState)(ServiceDetail);
