import Types from "../constants/benefits";
import axios from "axios";
import apiRequest from "../../api/apiRequest";
import { message, Modal } from "antd";
import { loadStripe } from "@stripe/stripe-js";
import env from "../../api/environment";
import { fetchDashboardData, fetchTransactions } from "./auth";
import { getMeasureData } from "./dashboard";

// actions
export const setBenefits = (data) => ({
  type: Types.SET_BENEFITS,
  payload: data,
});

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

// actions
// export const fetchBenefits = () => async (dispatch) => {
//   return await apiRequest({
//     method: "get",
//     url: "/benefits",
//   })
//     .then((res) => {
//       dispatch(setBenefits(res.data.data));
//     })
//     .catch((err) => {
//       message.error(err.data.data);
//       return false;
//     });
// };

// actions
// export const fetchLeaderBoard = () => async (dispatch) => {
//   return await apiRequest({
//     method: "get",
//     url: "/points-and-leaderboard/leaderboard",
//   })
//     .then((res) => {
//       dispatch({
//         type: Types.SET_LEADERBOARD,
//         payload: res.data.data,
//       });
//     })
//     .catch((err) => {
//       // message.error(err.data.data);
//       return false;
//     });
// };

// export const chargeCarbonFootprint = () => async (dispatch) => {
//   return await apiRequest({
//     method: "get",
//     url: "/carbon-footprint/charge",
//   })
//     .then((res) => {
//       dispatch(fetchTransactions());
//       return true;
//     })
//     .catch((err) => {
//       // message.error(err.data.data);
//       return false;
//     });
// };
// export const changeCarbonFootprint = (data) => async (dispatch) => {
//   return await apiRequest({
//     method: "POST",
//     url: "/carbon-footprint",
//     data,
//   })
//     .then((res) => {
//       dispatch(fetchDashboardData());
//       dispatch(getMeasureData());
//       return true;
//     })
//     .catch((err) => {
//       // message.error(err.data.data);
//       return false;
//     });
// };

// actions
// export const setLeaderBoardOptions = () => async (dispatch) => {
//   return await apiRequest({
//     method: "get",
//     url: "/points-and-leaderboard/toggle-setting/point",
//   })
//     .then((res) => {
//       dispatch({
//         type: Types.SET_LEADERBOARD,
//         payload: res.data.data,
//       });
//     })
//     .catch((err) => {
//       message.error(err.data.data);
//       return false;
//     });
// };

// actions;
export const createBenefit = (data) => async (dispatch) => {
  // var body = {};
  // body["image"] = await toBase64(data.image);

  const res = {
    createdAt: new Date().toLocaleDateString(),
    creator: "Susan Thomas",
    fundsAllocated: [],
    hoursToCarbonRatio: data.hoursToCarbonRatio,
    id: new Date().getTime(),
    image: "e5c7d010-de9a-11ec-92ea-098ea1c732c7.png",
    industry: data.industry,
    involved: [],
    longDescription: data.longDescription,
    moneyToCarbonRatio: data.moneyToCarbonRatio,
    public: true,
    selectedBy: [],
    shortDescription: data.shortDescription,
    title: data.title,
    type: data.benefitType,
    updatedAt: "2022-05-28T15:29:00.308Z",
    url: data.url,
    user: "62923cdbd71dd61d5ce847ab",
    __v: 0,
    _id: new Date().getTime(),
  };
  dispatch(addBenefit(res));

  return res;
  // return await apiRequest({
  //   method: "post",
  //   url: "/benefits",
  //   data: body,
  // })
  //   .then((res) => {
  //     dispatch(addBenefit(res.data.data));
  //   })
  //   .catch((err) => {
  //     message.error(err.data.data);
  //     return false;
  //   });
};

export const editBenefit = (data) => async (dispatch, getState) => {
  // return await apiRequest({
  //   method: "put",
  //   url: `/benefits`,
  //   data: data,
  // })
  //   .then((res) => {
  const res = {
    createdAt: new Date().toLocaleDateString(),
    creator: "Susan Thomas",
    fundsAllocated: [],
    hoursToCarbonRatio: data.hoursToCarbonRatio,
    id: data._id,
    image: "e5c7d010-de9a-11ec-92ea-098ea1c732c7.png",
    industry: data.industry,
    involved: [],
    longDescription: data.longDescription,
    moneyToCarbonRatio: data.moneyToCarbonRatio,
    public: true,
    selectedBy: [],
    shortDescription: data.shortDescription,
    title: data.title,
    type: data.benefitType,
    updatedAt: "2022-05-28T15:29:00.308Z",
    url: data.url,
    user: "62923cdbd71dd61d5ce847ab",
    __v: 0,
    _id: data._id,
  };
  dispatch({ type: Types.EDIT_BENEFIT, payload: res });

  Modal.success({
    content: "Congratulations! You have scucessfully edited the benefit ",
  });
  //   return true;
  // })
  // .catch((err) => {
  //   message.error(err.data.data);
  //   return false;
  // });
};

export const deleteBenefit = (data) => async (dispatch, getState) => {
  let benefits = await getState().benefits.benefits;
  // return await apiRequest({
  //   method: "delete",
  //   url: `/benefits/${data}`,
  // })
  //   .then((res) => {
  dispatch({
    type: Types.SET_BENEFITS,
    payload: benefits.filter((item) => item._id !== data),
  });
  //   return true;
  // })
  // .catch((err) => {
  //   return false;
  // });
};

// actions
// export const deleteBenefit = (data) => async (dispatch, getState) => {
//   let benefits = await getState().benefits.benefits;
//   return await apiRequest({
//     method: "delete",
//     url: `/benefits/${data}`,
//   })
//     .then((res) => {
//       dispatch({
//         type: Types.SET_BENEFITS,
//         payload: benefits.filter((item) => item._id !== data),
//       });
//       return true;
//     })
//     .catch((err) => {
//       return false;
//     });
// };

// actions
// export const allocateFund = (data) => async (dispatch, getState) => {
//   if (window.Stripe) {
//     var stripe = await loadStripe(env.stripePublishableKey);
//     return await apiRequest({
//       method: "post",
//       url: `/benefits/allocatefund`,
//       data: {
//         ...data,
//       },
//     })
//       .then((res) => {
//         // Modal.success({
//         //   content:
//         //     "Congratulations! Your donation will have a direct carbon impact on the planet.",
//         // });

//         dispatch(fetchDashboardData());
//         if (data.contribution == "employee") {
//           stripe.redirectToCheckout({ sessionId: res.data.data });
//         }
//         return res.data.data;
//       })
//       .catch((err) => {
//         Modal.error({ title: "Error", content: err.data.data });
//         return false;
//       });
//   }
// };

export const addBenefit = (data) => async (dispatch) => {
  dispatch({ type: Types.ADD_BENEFIT, payload: data });
};

export const setActiveBenefit = (data) => async (dispatch) => {
  dispatch({ type: Types.SET_ACTIVE_BENEFIT, payload: data });
};

export const setActiveService = (data) => async (dispatch) => {
  dispatch({ type: Types.SET_ACTIVE_SERVICE, payload: data });
};

export const purchaseService = (data) => async (dispatch, getState) => {
  if (window.Stripe) {
    var stripe = await loadStripe(env.stripePublishableKey);
    // return await apiRequest({
    //   method: "post",
    //   url: `/services`,
    //   data: {
    //     ...data,
    //   },
    // })
    //   .then((res) => {
    if (data.userType == "employer") {
      dispatch(fetchDashboardData());
      Modal.success({
        content:
          "Thank you for purchasing this service. You've been introduced automatically via email to the service provider. Additionally, an Account Manager will reach out within 24 hours.",
      });

      // return res.data.data;
    } else {
      stripe.redirectToCheckout({ sessionId: data.serviceId });
    }

    // return res.data.data;
    // })
    // .catch((err) => {
    //   if (err?.data?.status == 400) {
    //     Modal.error({
    //       title: "Error",
    //       content: "You have not added a payment method",
    //     });
    //   }
    //   return false;
    // });
  }
};

// export const getInvolved = async (data) => {
//   return await apiRequest({
//     method: "post",
//     url: `/benefits/getinvolved`,
//     data: {
//       email: data.email,
//       benefitId: data.benefitId,
//     },
//   })
//     .then((res) => {
//       return res.data;
//     })
//     .catch((err) => {
//       message.error(err.data.data);
//       return false;
//     });
// };

export const removeRecurring = (id) => async (dispatch, getState) => {
  let benefits = await getState().benefits.benefits;

  return await apiRequest({
    method: "delete",
    url: `/benefits/removerecurring/${id}`,
  })
    .then((res) => {
      dispatch(
        setBenefits(
          benefits.map((item) => {
            return {
              ...item,
              recurringCharge: id == item._id ? false : item.recurringCharge,
            };
          })
        )
      );
      return res.data;
    })
    .catch((err) => {
      message.error(err.data.data);
      return false;
    });
};

// export const sendBenefitFeedback = (data) => async (dispatch, getState) => {
//   return await apiRequest({
//     method: "post",
//     url: `/benefitfeedback/${data._id}`,
//     data: data,
//   })
//     .then((res) => {
//       Modal.success({
//         content:
//           "Congratulations! Your donation will have a direct carbon impact on the planet.",
//       });
//       return true;
//     })
//     .catch((err) => {
//       message.error(err.data.data);
//       return false;
//     });
// };

// export const createInvoice = (data) => {
//   return apiRequest({
//     method: "get",
//     url: `/invoices/generate-invoice/${data}`,
//   })
//     .then((res) => {
//       window.open(res.data.data, "_blank");
//       return true;
//     })
//     .catch((err) => {
//       message.error(err.data.data);
//       return false;
//     });
// };

// export const createAllInvoices = () => {
//   return apiRequest({
//     method: "get",
//     url: `/invoices/generate-invoice`,
//   })
//     .then((res) => {
//       window.open(res.data.data, "_blank");
//       return true;
//     })
//     .catch((err) => {
//       message.error(err.data.data);
//       return false;
//     });
// };

// export const generateReport = (data) => {
//   return apiRequest({
//     method: "post",
//     url: `/public-reports`,
//     data: data,
//   })
//     .then((res) => {
//       return true;
//     })
//     .catch((err) => {
//       message.error(err.data.data);
//       return false;
//     });
// };

// export const fetchReport = () => (dispatch) => {
//   return apiRequest({
//     method: "get",
//     url: `/public-reports`,
//   })
//     .then((res) => {
//       dispatch({ payload: res.data.data, type: Types.REPORT_GET });
//       return true;
//     })
//     .catch((err) => {
//       message.error(err.data.data);
//       return false;
//     });
// };

// export const getPublicReport = (id) => {
//   return apiRequest({
//     method: "get",
//     url: `/public-reports/generate/${id}`,
//   })
//     .then((res) => {
//       return res.data.data;
//     })
//     .catch((err) => {
//       message.error(err.data.data);
//       return false;
//     });
// };

// export const getAlerts = (id) => (dispatch) => {
//   return apiRequest({
//     method: "get",
//     url: `/alerts`,
//   })
//     .then((res) => {
//       dispatch({ type: Types.ALERTS_GET, payload: res.data.data });
//       return true;
//     })
//     .catch((err) => {
//       message.error(err.data.data);
//       return false;
//     });
// };

// export const getAlertTags = (id) => (dispatch) => {
//   return apiRequest({
//     method: "get",
//     url: `/alerts/tags`,
//   })
//     .then((res) => {
//       dispatch({ type: Types.TAGS_GET, payload: res.data.data });
//       return true;
//     })
//     .catch((err) => {
//       message.error(err.data.data);
//       return false;
//     });
// };

// export const setAlertTags = (data) => (dispatch) => {
//   return apiRequest({
//     method: "post",
//     url: `/alerts/tags`,
//     data,
//   })
//     .then((res) => {
//       // dispatch(getAlertTags());
//       // dispatch(getAlerts());
//       return true;
//     })
//     .catch((err) => {
//       message.error(err.data.data);
//       return false;
//     });
// };
