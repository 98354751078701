import { createStore, applyMiddleware, combineReducers, compose } from "redux";

import logger from "redux-logger";
import thunk from "redux-thunk";
import reducer from "./reducers/index";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default function configureStore(initialState) {
  const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(thunk, logger))
  );

  return store;
}
