import React, { useState } from "react";
import style from "../style/MainCard.module.css";
import environment from "../api/environment";
import { Popover, Button, Modal, message } from "antd";
import { removeRecurring } from "../redux/actions/benefits";
import { useDispatch } from "react-redux";

const imgBasePath = "https://admin.climatebenefits.com";

export default function MainCard({
  Data,
  setDetailVisible,
  imgRoute,
  button,
  onButtonClick,
  onboarding,
  maxHeight,
}) {
  const {
    id,
    image,
    title,
    shortDescription,
    type,
    recurringCharge,
    _id,
    outsideImage,
  } = Data;
  const [popVisible, setPopVisible] = React.useState(false);

  const dispatch = useDispatch();
  const content = (
    <div>
      <button
        onClick={() => {
          setPopVisible(false);
          Modal.confirm({
            title: "Remove Recurring",
            content: (
              <div>
                <p>This benefit will not be recurring anymore.</p>
              </div>
            ),
            async onOk() {
              // await dispatch(removeRecurring(_id));

              Modal.success({
                content: "We've stopped the recurring contribution.",
              });
            },
          });
        }}
        className="primaryBtn bg-danger"
      >
        Remove Recurring
      </button>
    </div>
  );

  return (
    <div
      className={style.MainCard}
      // style={{ maxHeight: maxHeight ? maxHeight : "315px" }}
    >
      {outsideImage}
      <img
        src={
          outsideImage
            ? image
              ? image
              : "/cbLogo.png"
            : `${imgBasePath}/img/${imgRoute}/${image}`
        }
        alt=""
        className={`${onboarding ? "w-100" : ""}`}
        style={{
          objectFit: outsideImage ? (image ? "cover" : "contain") : "cover",
        }}
      />
      <div className={`${style.cardContent} ${onboarding ? "w-100" : ""}`}>
        <div>
          <h3>{title}</h3>
          <p>{shortDescription} </p>
        </div>
        <div
          style={{ position: "absolute", right: "21px", fontWeight: "bold" }}
        >
          {/* {type} */}
          {recurringCharge ? (
            <Popover content={content} visible={popVisible} trigger="click">
              <img
                src="/images/clock.svg"
                className="ml-3"
                onClick={() => setPopVisible(true)}
                style={{ width: "15px", height: "15px", cursor: "pointer" }}
              />
            </Popover>
          ) : (
            ""
          )}
        </div>
        {/* {participat ? (
          <div className={style.participat_employee}>
            <h5>{participat.title}</h5>
            {participat.image1 ? (
              <div className="d-flex align-items-center">
                <img src={`/images/${participat.image1}`} alt="" />
                <img src={`/images/${participat.image2}`} alt="" />
                <img src={`/images/${participat.image3}`} alt="" />
                <img src={`/images/${participat.image4}`} alt="" />
                <span>{participat.remaining}</span>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )} */}
        <div className={style.cardFooter}>
          {onboarding ? (
            ""
          ) : (
            <button
              className="primaryBtn mr-1"
              onClick={() => setDetailVisible()}
            >
              Read More
            </button>
          )}
          {button ? (
            <button
              className="primaryBtn ml-1"
              onClick={() => onButtonClick(button)}
            >
              {button}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
