import React from "react";
import { Select, Input, Button, Form, Modal, Spin } from "antd";
import benefitstyle from "../style/ClientBenefits.module.css";
import { useDispatch } from "react-redux";

export default function EditEmployee({
  setModalVisible,
  isModalVisible,
  onFinish,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (isModalVisible)
      form.setFieldsValue({
        name: `${isModalVisible.firstName} ${isModalVisible.lastName}`,
        email: isModalVisible.email,
      });
    else
      form.setFieldsValue({
        firstName: "",
        lastName: "",
        email: "",
      });
  }, [isModalVisible]);

  const onFinishFailed = (errorInfo) => {};

  const onFormSubmit = async (values) => {
    console.log("Onsubmittttttttttttt", values);
    setLoading(true);
    await onFinish({ ...values, _id: isModalVisible._id });
    setLoading(false);
  };

  const AddFields = [
    { value: "name", title: "Name" },
    { value: "email", title: "Email" },
  ];
  return (
    <Modal
      footer={null}
      title="Edit Employee Data"
      visible={isModalVisible}
      onOk={() => {
        setModalVisible(false);
      }}
      onCancel={() => setModalVisible(false)}
      width={700}
    >
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        form={form}
        onFinish={onFormSubmit}
        onFinishFailed={onFinishFailed}
      >
        <div className="row" style={{ width: "100%", maxWidth: "700px" }}>
          {AddFields.map((item) => (
            <div className={`col-md-6`}>
              <Form.Item
                label=""
                name={item.value}
                rules={[
                  {
                    required: true,
                    message: `Please input your ${item.title.toLowerCase()}!`,
                  },
                ]}
              >
                <Input
                  placeholder={item.title}
                  className={benefitstyle.input}
                />
              </Form.Item>
            </div>
          ))}

          <div className="col-md-12">
            {loading ? (
              <div className="ml-3 my-3 mr-0">
                <Spin />
              </div>
            ) : (
              <button
                type="submit"
                className="primaryBtn"
                style={{ width: "180px" }}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
}
